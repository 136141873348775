.TodoSearchContent {
  &-title {
    margin: 15px 0;

    &-container {
      font-size: 1.05rem;
      margin-bottom: 25px;

      h2 {
        color: var(--theme-primary);
      }
    }
  }

  &-date-title {
    color: #b9b9b9;
    font-size: 0.9rem;
    margin-left: 7px;
  }

  /** Adds a bottom padding and minimum height */
  &-container {
    min-height: 700px;
    padding-bottom: 40px;
    width: 300px;

    @media screen and (min-width: 380px) {
      width: 350px;
    }

    @media screen and (min-width: 580px) {
      width: 500px;
    }

    @media screen and (min-width: 800px) {
      width: 450px;
    }

    @media screen and (min-width: 900px) {
      width: 550px;
    }

    @media screen and (min-width: 1200px) {
      width: 750px;
    }

    .rst__node:hover .rst__moveHandle {
      height: 0 !important;
    }
  }

  &-overdue-section-title {
    color: #fe4b87;
    font-weight: 600;
    margin: 0 0 12px 3px;
  }

  &-today-section-title {
    color: var(--theme-primary);
    font-weight: 600;
    margin: 25px 0 12px 3px;
  }
}
