.GeneralSettings {
  &-container {
    margin: 50px 0;
    padding: 0 55px;

    @media screen and (min-width: 1120px) {
      padding: 0 10%;
    }

    .ant-select-item-option {
      margin: 0 5px;
    }
  }

  &-divider {
    margin: 40px 0;
  }

  &-section-title {
    color: black;
    font-size: 1.1rem;
    font-weight: 500;
    margin: 10px 0;
  }

  &-timepicker {
    min-width: 150px;
    width: 100%;

    @media screen and (min-width: 650px) {
      min-width: 250px;
      width: auto;
    }
  }
}
