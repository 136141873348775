.Invitation {
  &-body {
    align-items: center;
    background-color: var(--theme-secondary-light);
    display: flex;
    justify-content: center;
    max-width: 100vw;
    min-height: 100vh;
  }

  &-content {
    margin: 40px 30px;
  }

  &-logo-container {
    align-items: flex-start;
    display: flex;
    justify-content: center;
    min-height: 150px;
  }

  &-buttons-container {
    display: flex;
    justify-content: space-around;
    padding: 0 15px;
    width: 100%;
  }

  &-card {
    border-radius: 15px;
    display: flex;
    justify-content: center;
    width: 450px;

    .ant-card-body {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin: 20px 0;
      width: 325px;
    }

    button {
      font-size: smaller;
      margin-top: 10px;
      width: 100%;
    }

    h4 {
      color: var(--theme-primary);
      font-weight: 600;
      margin-bottom: 40px;
    }

    .Invitation-form-item {
      margin-bottom: 14px;
      min-width: 100%;

      .ant-input-affix-wrapper {
        background-color: #fcfcfc;
        border-radius: 5px;

        .ant-input-prefix {
          color: #bbb9c6;
          margin-right: 10px;
        }

        .ant-input-suffix {
          color: #bbb9c6;
          cursor: pointer;
        }

        input {
          background-color: #fcfcfc;
        }
      }
    }
  }

  &-password-options-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    h4 {
      font-weight: 400;
      padding-top: 4px;
    }
  }

  &-btn {
    border-radius: 5px;
    margin-bottom: 5px;
    width: 100px !important;
  }

  &-error,
  &-success {
    left: 50%;
    min-width: 300px;
    position: absolute;
    top: 15%;
    transform: translate(-50%, 0);
    z-index: 200;
  }

  &-sign-in-container {
    color: gray;
    display: flex;
    justify-content: center;
    margin: 20px 0 10px;
    padding: 10px;
    width: 100%;

    h4 {
      margin-bottom: 0;
      margin-left: 5px;
    }

    h4:hover {
      color: var(--theme-light);
    }
  }

  &-alert-field {
    color: red;
    text-transform: uppercase;
  }

  &-help-text {
    font-size: 0.95rem;
    font-weight: 450;
    margin: 15px 0;
    text-align: center;

    span {
      color: var(--theme-primary);
    }
  }

  &-reject {
    background-color: var(--theme-delete-main);
    border-color: var(--theme-delete-main);
    color: white;

    &:hover {
      background-color: var(--theme-delete-light);
      border-color: var(--theme-delete-light);
      color: white;
    }
  }

  &-accept {
    &:hover {
      background-color: var(--theme-light);
      border-color: var(--theme-light);
      color: white;
    }
  }

  &-spinner {
    .ant-spin-blur {
      background: white !important;
      opacity: 0;
    }
  }

  &-not-found-text {
    color: black !important;
    font-size: 1.6rem;
    font-weight: 450 !important;
  }

  &-not-found-help-text {
    color: var(--theme-secondary-main);
    font-size: 0.8rem;
    font-weight: 350;
    margin: 15px 0;
    text-align: center;
  }

  &-title-text {
    color: black !important;
    font-size: 1.3rem;
  }
}
