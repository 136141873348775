.MeetingAgendaItem {
  &-container {
    .rdw-editor-wrapper {
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      padding: 10px;

      &:focus-within {
        border-color: var(--theme-light);
        box-shadow: 0 0 0 2px #1890ff33;
      }

      .rdw-editor-toolbar {
        border: unset;
      }

      .rdw-option-wrapper {
        border: unset;
        box-shadow: none;
        opacity: 0.6;
        padding: 0 10px 0 0;

        &:hover {
          color: var(--theme-primary);
        }
      }
    }

    .public-DraftEditor-content {
      box-shadow: unset !important;
      color: black !important;
      min-height: 100px;
      outline: none !important;
      padding: 0 10px;
    }

    .public-DraftEditorPlaceholder-root {
      color: #bfbfbf;
      padding: 0 10px;
    }

    .rdw-link-modal {
      border-color: var(--theme-secondary-light);
      border-radius: 5px;
      box-shadow: none;
      color: gray;
      height: 250px;
      left: -70px;
      position: absolute;
      top: -70px;
      z-index: 1000;

      button {
        border-radius: 5px;
        box-shadow: none;

        &:focus {
          background: var(--theme-dark);
          border-color: var(--theme-dark);
          color: white;
        }

        &:disabled {
          background: var(--theme-disabled) !important;
          border-color: var(--theme-disabled) !important;
          color: white !important;
        }

        &:hover {
          background: var(--theme-light);
          border-color: var(--theme-light);
          color: white;
        }
      }
    }
  }
}
