.RecurrenceTimePicker {
  &-picker {
    margin: 1px 5px 1px 0;
    width: 100px;

    .ant-picker-clear:hover {
      color: var(--theme-delete-main);
    }

    .ant-picker-input {
      width: 100px;
    }

    input {
      color: var(--theme-secondary-dark);
      font-size: 0.75rem !important;

      &:focus {
        outline: none !important;
      }
    }
  }
}
