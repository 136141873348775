.TodoActivityTypeItem {
  &-label {
    color: #161616;
    flex-grow: 1;
    font-weight: 350;
    overflow: block;
    white-space: initial;

    .UpcomingBadge-container {
      transform: translate(70px, -20px);
    }
  }

  &-count {
    color: #c2c1c9;
    min-width: 30px;
    text-align: right;
  }

  &-more-btn {
    background: none;
    border: unset;
    box-shadow: none;
    color: #b9b6c6;
    font-size: 0.7rem;

    &-container--active {
      display: block !important;
    }

    &:hover,
    &:focus,
    &:active {
      background: none;
    }

    &-container {
      display: none;
      position: absolute !important;
      right: 0;
      transform: translateX(18px);

      .rst__node:hover & {
        display: block;
      }
    }
  }

  &-container {
    border-radius: 5px;
    color: var(--theme-secondary-dark);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-weight: 300;
    margin-bottom: 5px;
    padding: 5px 5px 7px 12px;

    &:hover {
      background: var(--theme-secondary-light);
    }

    &--active {
      background: #d3d3dd !important;
      color: var(--theme-primary) !important;

      .TodoActivityTypeItem-label {
        font-weight: 500;
      }

      .TodoActivityTypeItem-count {
        color: var(--theme-primary) !important;
      }
    }

    &--first {
      width: 240px;
    }

    &--second {
      width: 220px;
    }

    &--third {
      width: 198px;
    }

    &--fourth {
      width: 178px;
    }

    &--disabled {
      background-color: unset !important;
      color: var(--theme-secondary-main) !important;
      pointer-events: none !important;

      .TodoActivityTypeItem-more-btn {
        display: none;
      }

      .TodoActivityTypeItem-label {
        color: var(--theme-secondary-main) !important;
      }
    }
  }

  .rst__node &-tag {
    margin-left: 7px;
    margin-right: 10px;
    margin-top: 5px;
    min-width: 14px;
    width: 14px;
  }
}
