.MeetingDashboardItem {
  &-container {
    background-color: white;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    margin: 0 0 20px;
    padding: 15px;
    width: 350px;
  }

  &-body {
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 15px;
    padding-bottom: 5px;
  }

  &-header {
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 15px;
    padding-bottom: 5px;

    &-title {
      cursor: pointer;
      font-size: 1.2rem;
      font-weight: 600;

      &:hover {
        color: var(--theme-primary);
      }
    }

    &-subtitle {
      color: var(--theme-secondary-main);

      span {
        color: var(--theme-secondary-main);
        font-size: 1.1rem;
        margin: 0 7px;
      }
    }
  }

  &-row {
    display: flex;
    font-size: 0.9rem;
    margin: 10px 0;
  }

  &-icon {
    color: #6b6b6b;
    min-width: 30px;
  }

  &-item {
    width: 100%;

    &-title {
      color: var(--theme-secondary-dark);

      .DraftEditor-editorContainer {
        color: var(--theme-secondary-dark);
      }

      .rdw-editor-toolbar {
        display: none;
      }
    }

    &-subtitle {
      color: var(--theme-secondary-main);
      font-size: 0.8rem;
    }

    .public-DraftStyleDefault-block {
      margin: 0;
    }
  }

  &-avatars {
    align-items: center;
    color: var(--theme-secondary-dark);
    display: flex;
    width: 100%;
  }

  &-grow {
    flex-grow: 1;
  }

  &-footer {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .ant-btn-text {
      border: unset !important;
      color: var(--theme-secondary-dark);
      padding: 0 5px;

      &:hover {
        color: var(--theme-primary);
      }
    }

    &-status {
      align-items: center;
      background-color: var(--theme-secondary-main);
      border-radius: 5px;
      color: white;
      display: flex;
      justify-content: center;
      min-width: 70px;
      padding: 5px;
    }

    &-title {
      color: black;
      font-weight: 600;
    }
  }
}
