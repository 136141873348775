.TasksSection {
  &-container {
    border-radius: 5px;
    width: 300px;

    @media screen and (min-width: 380px) {
      width: 350px;
    }

    @media screen and (min-width: 580px) {
      width: 500px;
    }

    @media screen and (min-width: 800px) {
      width: 450px;
    }

    @media screen and (min-width: 900px) {
      width: 550px;
    }

    @media screen and (min-width: 1200px) {
      width: 750px;
    }

    .rst__moveHandle {
      opacity: 1;
      transform: unset;
    }
  }

  &-add-icon {
    visibility: hidden;

    &-disabled {
      pointer-events: none;
      visibility: hidden !important;
    }
  }

  &-header {
    border-bottom: 1px solid #efefef;
    color: var(--theme-primary);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    padding: 5px 0 10px;

    &:hover .TasksSection-add-icon {
      visibility: visible;
    }
  }

  &-label-container {
    cursor: text;
    flex-grow: 1;
    margin: 0 15px;

    input {
      background-color: #f7f6f8;
      border: 1px solid rgb(212, 212, 212) !important;
      border-radius: 5px;
      box-shadow: unset !important;
      height: 100%;
      padding: 8px 10px;

      &:focus {
        border: 1px solid var(--theme-light) !important;
        box-shadow: 0 0 0 1px #1890ff33 !important;
        outline: none !important;
      }
    }
  }

  &-label {
    overflow: hidden;
    padding: 0 5px;
    text-overflow: ellipsis;
  }

  &-expand-icon {
    transition: all 0.3s ease;

    i {
      font-size: 0.7rem;
    }
  }

  &-add-icon .ant-btn-text {
    background: inherit;
    border: unset !important;
    box-shadow: none !important;
    color: inherit;
    font-size: 0.9rem;
    font-weight: 300;
    margin: 2px 10px 0;

    i {
      font-size: 0.6rem;
      font-weight: 600;
      margin-right: 5px;
    }
  }

  &-more-icon .ant-btn-circle {
    background: inherit;
    border: unset !important;
    box-shadow: none !important;
    color: #b9b6c6;

    i {
      font-size: 0.7rem;
    }

    &:hover {
      color: var(--theme-primary);
    }
  }

  &-expand-icon-active {
    transform: rotate(90deg);
  }

  &-add-task {
    margin-bottom: 25px;

    .AddTask-calendar-container {
      position: absolute;
    }
  }
}

/** Fixes the visibility of all drag handlers of tasks on section hover */
.rst__node .TasksSection {
  &-container {
    &:hover .rst__moveHandle,
    &:hover .rst__loadingHandle {
      height: 0 !important;
    }

    .rst__node:hover .rst__moveHandle,
    .rst__node:hover .rst__loadingHandle {
      height: 14px !important;
    }
  }

  &-body {
    padding: 7px 0;
    transition: all 0.3s ease !important;
  }

  &-body-hidden {
    height: 0 !important;
    opacity: 0;
    overflow: hidden;
    padding: 0;
  }
}

/** Fixes the visibility of all task more buttons on section hover */
.rst__node:hover .TasksSection-container {
  .TaskItem-more-btn-container {
    display: none;
  }

  .TaskItem-edit {
    display: none;
  }
}
