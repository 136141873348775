.NotificationItem {
  &-container {
    align-items: center;
    background: #f5f6fa;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;
    display: flex;
    min-height: 80px;
    padding: 10px 15px;

    &-read {
      background: white;
    }

    &-read .NotificationItem-icon {
      background: #f5f6fa;
    }
  }

  &-icon {
    align-items: center;
    background: white;
    border-radius: 50%;
    color: var(--theme-primary);
    display: flex;
    font-size: 0.9rem;
    height: 45px;
    justify-content: center;
    margin-right: 15px;
    min-width: 45px;
  }

  &-meta {
    align-items: center;
    color: #c7c6d0;
    display: flex;
    font-size: 0.75rem;
    justify-content: space-between;
    min-width: 200px;
  }

  &-content {
    color: #5e5e5e;
    font-size: 0.8rem;
  }

  &-read {
    margin-left: 15px;
    min-width: 10px;
  }

  &-circle {
    background: var(--theme-primary);
    border-radius: 50%;
    height: 7px;
    width: 7px;
  }

  &-margin-right {
    margin-right: 5px;
  }
}
