.MeetingSummary {
  &-item {
    &-container {
      background-color: white;
      border: 1px solid var(--theme-secondary-light);
      border-radius: 7px;
      margin-bottom: 10px;

      .ant-list-item {
        padding: 2px  0;
      }

      .ant-list-split .ant-list-item {
        border: unset;
      }

      .ant-list-item-meta-title {
        color: var(--theme-secondary-dark);
      }
    }

    &-header {
      border-bottom: 1px solid var(--theme-secondary-light);
      padding: 10px 25px;
    }

    &-body {
      padding: 20px 25px 25px;

      .DetailedTasksList-container-empty {
        border: unset;
      }

      .TaskItem-container--first {
        width: 260px;

        @media screen and (min-width: 400px) {
          width: 330px;
        }

        @media screen and (min-width: 600px) {
          width: 465px;
        }

        @media screen and (min-width: 800px) {
          width: 645px;
        }
      }

      .rst__moveHandle {
        visibility: hidden !important;
      }

      .DetailedTasksList-completed-section {
        display: none;
      }

      .DetailedTasksList-completed-title {
        display: none;
      }

      .FetchCompletedTasks-container {
        display: none;
      }

      .TaskItem-edit {
        display: none;
      }

      .TaskItem-more-btn-container {
        display: none;
      }
    }
  }

  &-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-shared-item {
    width: 100%;

    @media screen and (min-width: 600px) {
      width: 49%;
    }

    .ant-list-item-meta {
      a {
        color: var(--theme-secondary-dark) !important;

        &:hover {
          color: var(--theme-primary) !important;
        }
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .TodoShareUserItem-user-delete {
      display: none;
    }

    .ant-divider-horizontal {
      display: none !important;
    }

    .TodoShareUserItem-user-item {
      padding: 5px 0;
    }
  }

  &-field {
    margin-bottom: 20px;

    .DraftEditor-editorContainer {
      color: var(--theme-secondary-dark);
    }

    .rdw-editor-toolbar {
      display: none;
    }

    &-objective {
      margin-bottom: 10px;
    }

    &-title {
      font-weight: 600;
    }
  }

  &-objective {
    margin-bottom: 5px;
  }

  &-hypen {
    padding-left: 3px;
    padding-right: 0;
  }

  &-join-link {
    margin-top: 10px;
  }
}
