.MeetingEvent {
  &-container {
    .ant-collapse {
      border: unset;
    }

    .ant-collapse-item {
      background-color: white;
      border: 1px solid #d9d9d9;
      border-radius: 7px !important;
      margin-bottom: 10px;
    }

    .ant-collapse-header {
      background: none;
      border: unset;

      .anticon {
        display: none !important;
      }
    }

    .ant-collapse-content {
      background: none;
    }
  }

  &-header {
    padding: 0 10px;
    position: relative;
  }

  &-body {
    padding: 10px 10px 20px;

    .rst__tree {
      transform: translate(-55px);
    }

    .rst__nodeContent {
      justify-content: flex-start;
      left: 0 !important;
      width: 100%;
    }

    .rst__moveHandle {
      margin-top: 17px;
      transform: translate(-3px);
    }

    .rst__node div {
      width: 100%;
    }
  }

  &-field-container {
    margin-bottom: 25px;
    max-width: 450px;
  }

  &-field-label {
    color: black;
    font-weight: 600;
    margin-bottom: 7px;
  }

  &-link-btn {
    border: unset;
    border-radius: 5px;
    box-shadow: unset;
    color: var(--theme-secondary-main);
    font-size: 0.8rem;
    margin: 5px 0 0;
    padding: 8px 10px 5px 8px !important;
    width: 30px;

    &:hover {
      background-color: var(--theme-secondary-light) !important;
      color: var(--theme-primary);
    }
  }

  &-field-body {
    .ant-divider-horizontal {
      border-color: white;
      margin: 0;
    }

    .TodoShareUserItem-user-item {
      padding: 5px 0;
    }

    input {
      border-radius: 5px;
      padding: 10px 15px;
    }

    .ant-select {
      border-radius: 5px;
      width: 100%;
    }

    .ant-select-selector {
      height: 45px !important;
      padding: 7px 15px !important;
    }

    .ant-select-item {
      margin: 0 !important;
    }

    .ant-select-item-option {
      margin: 0 !important;
      padding: 12px 15px;
    }

    .ant-select-item-option-selected {
      background-color: var(--theme-hover-light);
      color: black;
    }

    i,
    .MeetingEvent-icon {
      margin-right: 10px;
    }

    .rdw-editor-wrapper {
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      padding: 10px;

      &:focus-within {
        border-color: var(--theme-light);
        box-shadow: 0 0 0 2px #1890ff33;
      }

      .rdw-editor-toolbar {
        border: unset;
      }

      .rdw-option-wrapper {
        border: unset;
        box-shadow: none;
        opacity: 0.6;
        padding: 0 10px 0 0;

        &:hover {
          color: var(--theme-primary);
        }
      }
    }

    .public-DraftEditor-content {
      box-shadow: unset !important;
      color: black !important;
      min-height: 90px;
      outline: none !important;
      padding: 0 10px;
    }

    .public-DraftEditorPlaceholder-root {
      color: #bfbfbf;
      padding: 0 10px;
    }

    .rdw-link-modal {
      border-color: var(--theme-secondary-light);
      border-radius: 5px;
      box-shadow: none;
      color: gray;
      height: 250px;

      button {
        border-radius: 5px;
        box-shadow: none;

        &:focus {
          background: var(--theme-dark);
          border-color: var(--theme-dark);
          color: white;
        }

        &:disabled {
          background: var(--theme-disabled) !important;
          border-color: var(--theme-disabled) !important;
          color: white !important;
        }

        &:hover {
          background: var(--theme-light);
          border-color: var(--theme-light);
          color: white;
        }
      }
    }
  }

  &-create-btn {
    border-radius: 5px;
    min-height: 35px;
    min-width: 80px;

    &:focus {
      background: var(--theme-dark);
      border-color: var(--theme-dark);
      color: white;
    }

    &:disabled {
      background: var(--theme-disabled) !important;
      border-color: var(--theme-disabled) !important;
      color: white !important;
    }

    &:hover {
      background: var(--theme-light);
      border-color: var(--theme-light);
      color: white;
    }
  }

  &-autocomplete {
    margin-bottom: 10px;
    outline: none;

    .ant-select-selection-search-input {
      height: 45px !important;
      outline: none !important;
      padding: 15px 5px !important;

      &:focus {
        box-shadow: none;
      }
    }

    .ant-select-item-option {
      margin: 0;
      padding: 10px 15px;
    }
  }

  &-required {
    color: var(--theme-primary);
    font-size: 1rem;
    font-weight: 500;
    margin: 3px 0 0 2px;
  }

  &-tag {
    left: 40px;
    position: relative;

    @media screen and (min-width: 400px) {
      left: 40px;
      position: relative;
    }

    @media screen and (min-width: 600px) {
      left: unset;
      position: absolute;
      right: 0;
      top: 13px;
    }

    @media screen and (min-width: 800px) {
      right: 0;
      top: 13px;
    }
  }

  &-connect-btn {
    border: unset !important;
    color: var(--theme-primary);
    padding: 0;

    &:hover span {
      text-decoration: underline;
    }
  }

  &-tip {
    color: var(--theme-secondary-main);
  }

  &-draft-btn {
    border: unset !important;
    box-shadow: unset !important;
    color: orange;
    outline: unset !important;
    padding: 0 20px 0 0;

    &:hover,
    &:focus {
      box-shadow: unset !important;
      color: rgb(255, 180, 41);
      outline: unset !important;
    }
  }

  &-confirm-modal {
    .ant-modal-body {
      background-color: white;
      color: black;
      font-weight: 600;
    }

    .anticon-exclamation-circle {
      display: none;
    }

    .ant-btn {
      background: unset;
      border: unset !important;
      border-radius: 5px;
      box-shadow: unset !important;
      color: var(--theme-secondary-dark);
      outline: unset !important;

      &:hover {
        background: var(--theme-secondary-light) !important;
      }
    }

    .ant-btn-primary {
      color: var(--theme-primary);
    }
  }

  &-schedule-body {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .CustomDatePicker-dateinput {
      padding-right: 25px !important;
      text-align: center;
      width: 115px !important;
    }

    .react-datepicker__input-container input {
      font-size: 0.75rem;
      min-width: 70px;
      padding: 13px 5px;
      width: 65px;
    }
  }

  &-link-input {
    border: unset !important;
    box-shadow: none !important;
    color: var(--theme-primary);
    cursor: pointer;
    padding: 10px 0 !important;

    &:hover {
      background: var(--theme-secondary-light);
      padding: 10px !important;
      text-decoration: underline;
    }
  }

  &-link-container {
    display: flex;

    a {
      flex-grow: 1;
      margin-right: 10px;
    }
  }

  &-space {
    color: var(--theme-secondary-main);
  }

  &-recurring-btn {
    align-items: center;
    border: unset;
    border-radius: 5px;
    box-shadow: unset;
    color: var(--theme-secondary-dark);
    display: flex;
    justify-content: center;
    min-width: unset;
    padding: 15px 0 15px 10px;

    &:hover {
      background: var(--theme-secondary-light);
    }
  }
}
