.Loading {
  &-container {
    align-items: center;
    animation: rotateY 2s infinite;
    animation-timing-function: linear;
    background: white;
    border: 1px solid var(--theme-secondary-light);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0, rgba(27, 31, 35, 0.15) 0 0 0 1px;
    display: flex;
    height: 100px;
    justify-content: center;
    left: 50%;
    position: absolute;
    top: 65%;
    transform: translate(-50%, -50%);
    width: 100px;
  }

  &-image {
    background: url('/virtunus-logo-only.svg') white;
    background-size: contain;
    height: 50px;
    width: 50px;
  }

  @keyframes rotateY {
    0% {
      transform: translate(-50%, -50%) rotateY(0deg);
    }

    100% {
      transform: translate(-50%, -50%)   rotateY(360deg);
    }
  }
}
