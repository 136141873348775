.AddTask {
  &-container {
    padding-left: 76px;
    padding-right: 15px;
  }
  &-options {
    &-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      min-width: 100%;
      padding: 7px 0 10px;
      [ant-click-animating-without-extra-node='true']::after,
      .ant-click-animating-node {
        animation: unset;
      }
    }

    &-space {
      flex-grow: 1;
    }

    &-item {
      color: #bbbac8;
      cursor: pointer;
      font-size: 0.9rem;
      margin-right: 5px;
      position: relative;
      transition: transform 0.3s ease;

      @media screen and (min-width: 500px) {
        margin-right: 20px;
      }

      button {
        all: unset;
        padding: 0 5px;

        &:hover {
          background-color: var(--theme-secondary-light);
          border-radius: 3px;
        }
      }

      &:hover {
        color: var(--theme-primary);
      }
    }
  }

  &-input-container {
    background-color: #fcfcfc;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
    cursor: text;

    textarea {
      border: unset;
      box-shadow: unset !important;
      outline: unset !important;
      resize: none;
    }

    .ant-input {
      background-color: inherit;
      border-radius: 5px;
      padding: 10px 100px 0 14px !important;
    }

    &:hover {
      border-color: var(--theme-focus) !important;
    }

    &:focus-within {
      border-color: var(--theme-focus) !important;
      box-shadow: 0 0 0 2px #b4b4b433;
    }
  }

  &-btn {
    background: var(--theme-primary);
    border-color: var(--theme-primary);
    border-radius: 5px;
    color: white;
    font-size: 0.7rem;
    font-weight: 300;

    &:focus {
      background: var(--theme-dark);
      border-color: var(--theme-dark);
      color: white;
    }

    &:disabled {
      background: var(--theme-disabled) !important;
      border-color: var(--theme-disabled) !important;
      color: white !important;
    }

    &:hover {
      background: var(--theme-light);
      border-color: var(--theme-light);
      color: white;
    }
  }

  &-cancel-btn {
    background: none !important;
    border: unset !important;
    box-shadow: none;
    color: #bbbbc7 !important;
    margin-right: 5px;
    outline: none !important;

    &:hover,
    &:focus {
      background-color: inherit !important;
      border: unset !important;
      color: #fe4b87 !important;
      outline: none !important;
    }
  }

  &-calendar-container {
    align-items: center;
    border-left: 1.5px solid #bbbac8;
    color: #bbbac8;
    display: flex;
    height: 12px;
    justify-content: center;
    margin: 20px 20px 20px 0;
    padding: 0 0 2px 10px;
    position: absolute !important;
    right: 0;
    top: calc(50% - 25px);
    width: 30px;

    button {
      all: unset;
      background: none !important;
      padding: 0 5px;

      &:hover {
        background-color: var(--theme-secondary-light) !important;
        border-radius: 3px;
      }
    }

    i {
      cursor: pointer;
      transition: transform 0.3s ease;
    }

    &:hover i {
      color: var(--theme-primary);
    }
  }

  &-meta-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    font-weight: 300;
    margin: 2px 0 7px;
    max-width: 80%;
    padding: 5px 14px 4px;
    z-index: 10;
  }

  &-meta-tags {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &-meta-date {
    background-color: white;
    border: 1px solid #dedede;
    border-radius: 3px;
    color: #94bfe0;
    font-size: 0.64rem;
    font-weight: 600;
    margin-bottom: 1px;
    margin-right: 15px;
    padding: 1px 3px;
  }

  &-top-priority {
    color: var(--theme-task-top-priority);
  }

  &-middle-priority {
    color: var(--theme-task-middle-priority);
  }

  &-low-priority {
    color: var(--theme-task-low-priority);
  }

  &-no-priority {
    color: var(--theme-task-no-priority);
  }

  &-reminders-count,
  &-labels-count {
    color: var(--theme-primary);
    float: right;
    font-size: 0.7rem;
  }

  &-priority-tooltip {
    span {
      background-color: #fff;
      border-radius: 2px;
      font-size: 0.8rem;
      font-weight: 600;
      padding: 1px 2px;
    }

    span:nth-child(1) {
      color: var(--theme-task-top-priority);
    }

    span:nth-child(2) {
      color: var(--theme-task-middle-priority);
    }

    span:nth-child(3) {
      color: var(--theme-task-low-priority);
    }

    span:nth-child(4) {
      color: var(--theme-task-no-priority);
    }
  }
}
