.Item {
    width: 70px;
    height: 65px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    @media (max-width: 1400px){
        width: 60px;
        height: 55px;
    }
    .Date {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #222222;
    }
    .Day {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        color: #222222;
        text-transform: uppercase;
    }
}

.InActive {
    border: 1px solid rgba(187, 186, 199, 0.2);
    .Date {
        color: #bbbac7;
    }
    .Day {
        color: #bbbac7;
    }
}
.Today {
    border: 1px solid rgba(49, 97, 241, 0.3);
    .Date {
        color: #3161f3;
    }
    .Day {
        color: #3161f3;
    }
}

.Active {
    background: rgba(49, 97, 241, 0.95);
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.05);
    .Date {
        color: #fff;
    }
    .Day {
        color: #fff;
    }
}
