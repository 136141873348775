.TaskCompletionDateTag {
  &-container {
    color: #bbb8c7;
    font-size: 0.65rem;
    font-weight: 400;
    padding: 0 15px 0 0;
    text-transform: uppercase;

    @media screen and (min-width: 1000px) {
      padding: 0 15px;
    }
  }

  &-this-week {
    color: var(--theme-task-this-week);
  }

  &-today {
    color: var(--theme-task-today);
  }

  &-tomorrow {
    color: var(--theme-task-tomorrow);
  }

  &-due {
    color: var(--theme-task-due);
  }
}
