.AddProject {
  &-folder-container {
    margin: 30px 5px 5px;
  }

  &-colorpicker-container {
    margin: 30px 5px 5px;
  }

  &-extra-container {
    margin: 30px 5px 5px;
  }

  &-title-container {
    margin: 10px 0 5px;

    input {
      border-radius: 5px;
    }
  }
}
