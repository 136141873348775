.RecurrencePicker {
  &-head {
    border-bottom: 1px solid #e3e2eb;
    color: var(--theme-primary);
    font-weight: 600;
    min-height: 40px;
    padding: 10px 20px 0;

    i {
      color: var(--theme-secondary-main);
      margin-right: 10px;
      margin-top: 1px;
      padding: 3px 5px;

      &:hover {
        background: var(--theme-secondary-light);
        border-radius: 5px;
      }
    }
  }

  &-body {
    padding: 20px 0;
  }

  &-label {
    color: var(--theme-secondary-dark);
    font-weight: 500;
    min-width: 90px;
  }

  &-cancel-btn {
    background-color: #f3f3f3 !important;
    border: 1px solid #f3f3f3;
    border-radius: 5px;
    color: var(--theme-secondary-dark);
    cursor: pointer;
    font-size: 0.8rem;
    margin: 0;
    margin-right: 5px;
    padding: 7px;
    text-align: center;
    width: 120px;

    &:hover {
      background: var(--theme-secondary-light) !important;
    }
  }

  &-done-btn {
    background-color: var(--theme-primary) !important;
    border: 1px solid var(--theme-primary) !important;
    border-radius: 5px;
    color: white !important;
    cursor: pointer;
    font-size: 0.8rem;
    height: 35px;
    margin: 0;
    margin-left: 5px;
    padding: 7px;
    text-align: center;
    width: 120px;

    &:hover {
      background-color: var(--theme-light) !important;
    }

    &:disabled {
      background-color: var(--theme-disabled) !important;
    }
  }

  &-btn-container {
    background-color: inherit;
    border: unset;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 10px 30px 5px;
    padding: 0;
  }

  &-row {
    align-items: center;
    display: flex;
    padding: 10px 20px;
  }

  &-interval {
    margin-right: 10px;
    width: 70px;

    input {
      color: var(--theme-secondary-dark);
    }
  }

  &-unit {
    color: var(--theme-secondary-dark) !important;
    text-transform: capitalize !important;
    width: 255px;

    .ant-select-selection-item {
      i {
        display: none !important;
      }
    }

    &-dropdown {
      border: 1px solid var(--theme-secondary-light);
      border-radius: 10px !important;
      padding: 5px 0;
    }

    &-option {
      background: white !important;
      border-radius: 0;
      color: var(--theme-secondary-dark) !important;
      margin: 2px 0;
      text-transform: capitalize !important;

      .ant-select-item-option-content {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }

      &.ant-select-item-option-selected {
        background: var(--theme-secondary-light) !important;
        color: var(--theme-primary) !important;
        font-weight: 500;
      }

      &:hover {
        background: var(--theme-secondary-light) !important;
      }
    }
  }

  &-datepicker {
    width: 335px;

    input {
      color: var(--theme-primary);

      &:focus {
        outline: none !important;
      }
    }

    &-dropdown {
      .ant-picker-panel-container {
        border-radius: 7px;
        padding: 10px 0;
      }

      .ant-picker-panel {
        border: unset;
      }

      .ant-picker-cell-in-view {
        color: var(--theme-secondary-dark);
      }

      .ant-picker-header-super-prev-btn {
        display: none;
      }

      .ant-picker-header-super-next-btn {
        display: none;
      }

      .ant-picker-header {
        border-bottom: unset;
        color: var(--theme-secondary-main);
        padding: 0 30px;
      }

      .ant-picker-cell-inner {
        border-radius: 12px !important;
        font-size: 0.7rem;
        line-height: 24px;
        min-height: 24px;
        min-width: 24px;
      }

      .ant-picker-footer {
        display: none;
      }
    }
  }

  &-back {
    cursor: pointer;
  }

  &-spinning-container {
    background-color: white;
    border: 1px solid #e3e2eb;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 18px 50px -10px;
    min-width: 475px;
  }
}
