.TodoUpcomingPage {
  &-container {
    animation: fadeAnimation 2s ease;
    max-height: calc(100vh - 100px);
    overflow: auto;
    scroll-behavior: smooth;
    width: 100%;

    .rst__moveHandle {
      display: none;
    }
  }
}

@keyframes fadeAnimation {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
