.FetchCompletedTasks {
  &-container {
    margin: 25px 0 10px;
  }

  &-btn {
    background: inherit !important;
    border: unset !important;
    color: var(--theme-secondary-dark);
    font-size: 0.65rem;
    padding: 0 3px;
    text-transform: uppercase;

    &:hover,
    &:focus {
      border: unset !important;
      outline: none !important;
    }

    i {
      margin-right: 7px;
    }
  }

  &-loading {
    border: unset !important;
    color: black;
    color: var(--theme-secondary-main);
    font-size: 0.7rem !important;
    font-weight: 500 !important;
    padding: 5px 3px;
    text-transform: uppercase;

    .anticon {
      color: var(--theme-primary);
      font-size: 0.8rem;
      font-weight: 500;
      margin-left: 10px;
    }
  }

  &-empty {
    border: unset !important;
    color: black;
    color: #cecece;
    font-size: 0.7rem !important;
    font-weight: 500 !important;
    padding: 5px 3px !important;
    text-transform: capitalize;

    i {
      margin-right: 7px;
    }
  }
}
