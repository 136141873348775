.withTaskSider {
  &-container {
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 300;

    &--disabled {
      pointer-events: none;
    }
  }

  &-inner-container {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
  }

  &-sider {
    background: #f9f9f9;
    height: 100%;
    transition: width 0.5s ease-in-out;
    width: 0;

    &-content {
      height: 100%;
      position: relative;
      width: 100%;

      @media screen and (min-width: 400px) {
        width: 400px;
      }

      @media screen and (min-width: 600px) {
        width: 600px;
      }

      @media screen and (min-width: 800px) {
        width: 800px;
      }
    }

    &-open {
      width: 100%;

      @media screen and (min-width: 400px) {
        width: 400px;
      }

      @media screen and (min-width: 600px) {
        width: 600px;
      }

      @media screen and (min-width: 800px) {
        width: 800px;
      }
    }
  }

  &-header {
    align-items: center;
    background-color: white;
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding: 0 50px 0 40px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;

    i {
      color: #8a8a8a;
      cursor: pointer;
      font-size: 0.95rem;
      font-weight: 700;
    }

    i:hover {
      color: rgb(230, 100, 122);
    }
  }

  &-footer {
    align-items: center;
    background-color: white;
    bottom: 0;
    display: flex;
    min-height: 60px;
    padding: 10px 55px;
    position: absolute;
    width: 100%;
    z-index: 200;
  }

  &-body {
    height: 100%;
    overflow: auto;
    padding-bottom: 120px;
    padding-top: 60px;
    width: 100%;
  }
}
