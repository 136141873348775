.TaskReminderHeader {
  &-container {
    align-items: center;
    display: flex;
    font-size: 0.85rem;
    padding: 10px;
  }

  &-title {
    color: var(--theme-primary);
    flex-grow: 1;
    font-weight: 600;
  }

  &-subtitle {
    color: var(--theme-secondary-main);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
