.TodoFilterDeleteButton {
  &-modal-title {
    color: #fe4b87;
  }

  &-modal-container {
    .anticon {
      color: #fe4b87 !important;
    }

    .ant-btn {
      border-radius: 5px;
    }

    .ant-btn-primary {
      background: #fe4b87;
      border-color: #fe4b87;
      color: white;

      &:hover {
        background: #f50756;
        border-color: #f50756;
        color: white;
      }
    }
  }
}
