.MeetingAgenda {
  &-container {
    max-width: 440px;

    .ant-collapse {
      border: unset;
    }

    .ant-collapse-item {
      background-color: white;
      border: 1px solid #d9d9d9;
      border-radius: 5px !important;
      margin-bottom: 15px;
    }

    .ant-collapse-header {
      align-items: center !important;
      border: unset;
      border-bottom: 1px solid #d9d9d9;
      display: flex !important;
      margin: 0;
      padding: 12px 20px !important;
      width: 100%;
    }

    .ant-collapse-content {
      background-color: unset;
      border: unset;
      padding: 5px 5px 15px;
    }

    .ant-collapse-extra {
      button {
        border: unset;
        color: var(--theme-secondary-dark) !important;

        &:hover {
          background-color: var(--theme-secondary-light);
        }
      }
    }

    input {
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 10px 15px;
    }

    textarea {
      border-radius: 5px;
      margin-bottom: 10px;
      padding: 10px 15px;
    }
  }

  &-header-title {
    flex-grow: 1;
    font-weight: 600;

    span {
      color: #bfbfbf;
    }
  }

  &-add-btn {
    background: none !important;
    border: unset !important;
    border-radius: 5px;
    color: var(--theme-secondary-dark);
    margin-bottom: 5px;
    padding: 5px !important;

    i {
      margin-right: 5px;
    }

    &:hover {
      background-color: var(--theme-secondary-light) !important;
      text-decoration: underline solid blue !important;
    }
  }
}
