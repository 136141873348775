.TodoTaskActivityTag {
  &-container {
    background-color: var(--theme-primary);
    border-radius: 3px;
    color: white;
    cursor: pointer;
    font-size: 0.6rem;
    font-weight: 350;
    margin-right: 12px;
    padding: 1px 5px;
  }
}
