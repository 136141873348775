.FilterColorPicker {
  &-container {
    align-items: center;
    display: flex;
    justify-content: space-around;

    .ant-checkbox-inner {
      background: var(--cp-background) !important;
      background-size: contain;
      border: unset !important;
      height: 25px;
      mask-image: url('/tint-solid.svg');
      mask-position: center;
      mask-repeat: no-repeat;
      mask-size: contain;
      padding: 3px;
      width: 25px;
    }

    .ant-checkbox {
      border: unset !important;
      padding: 3px;

      &:hover,
      &:focus,
      &::after {
        border: unset !important;
        border-color: var(--cp-background);
      }
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin: 2px;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      left: 10px;
      top: 12px;
    }
  }

  &-more-btn {
    background-color: inherit;
    border: unset;
    box-shadow: none;
    color: gray;
  }

  &-chrome-picker {
    cursor: pointer;
  }

  &-selected {
    border: 1.5px solid #d3d3d3;
    border-radius: 5px;
    box-shadow: 10px 10px 30px -14px #d3d3d3;
  }
}
