.RecurrenceWeekPicker {
  &-container {
    border: 1px solid #e3e2eb;
    border-radius: 5px;
    margin: 0 20px;
    padding: 10px;
  }

  &-header {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
  }

  &-toggler {
    background: var(--theme-secondary-light);
    border: 1px solid #e3e2eb;
    border-radius: 15px;
    cursor: pointer;
    font-size: 0.8rem;
    margin: 2px;
    min-width: 40px;
    padding: 2px;
    text-align: center;
    text-transform: capitalize;

    &:hover {
      background: white;
    }
  }

  &-label {
    color: var(--theme-secondary-dark);
    font-weight: 500;
    min-width: 80px;
    text-transform: capitalize;
  }

  &-row {
    align-items: center;
    display: flex;
    padding: 10px 0;
  }

  &-toggler-active {
    background-color: var(--theme-primary) !important;
    border-color: var(--theme-primary) !important;
    color: white !important;
  }

  &-time-container {
    display: flex;
    flex-wrap: wrap;
    width: 340px;

    .MomentTimePicker-container {
      margin: 0 5px 5px 0;
    }
  }

  &-plus {
    border: unset !important;
    color: var(--theme-secondary-main);
    outline: none !important;

    &:focus {
      outline: none !important;
    }
  }
}
