.AddTaskSection,
.rst__node .AddTaskSection {
  &-hover-container {
    align-items: center;
    color: #fe4b87;
    cursor: pointer;
    display: flex;
    font-weight: 300;
    justify-content: center;
    min-height: 50px;
    overflow: hidden;
    width: 0;
  }

  &-container {
    margin: 0 2px;

    &:hover .AddTaskSection-hover-container {
      width: 100%;
    }
  }

  &-divider {
    background: #fe4b87;
    flex-grow: 1;
    height: 1px !important;

    &-label {
      font-size: 0.7rem;
      font-weight: 500;
      padding: 0 20px;
    }
  }

  &-input-container {
    display: flex;
    flex-direction: row;
    margin: 15px 0 !important;
    padding: 10px 0;

    .TodoProjectContent-add-section-container & {
      margin: 0;
      padding: 0;
    }

    button {
      border-radius: 5px;
      font-size: 0.6rem;
      margin-left: 10px;
    }

    .ant-input {
      background-color: #f9f9f9;
      border-radius: 5px;
    }

    .ant-btn-text {
      color: #bebdc4;
    }
  }

  &-add-btn {
    background-color: var(--theme-primary);
    border: unset;
    color: white;

    /* stylelint-disable no-descending-specificity */
    &:disabled {
      background-color: var(--theme-disabled) !important;
      color: white !important;
    }

    &:hover {
      background-color: var(--theme-light);
      color: white;
    }
  }

  &-cancel-btn {
    border: unset !important;

    &:hover {
      color: var(--theme-delete-main);
    }

    &:focus {
      outline: none !important;
    }
  }
}
