.TaskReminderPicker {
  &-container {
    background-color: white;
    border: 1px solid #e3e2eb;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 18px 50px -10px;
    min-width: 255px;
  }

  &-confirm-container {
    display: flex;
    justify-content: center;
    padding: 10px 10px 15px;
  }

  &-confirm-btn {
    font-size: 0.8rem;
    padding: 5px 7px;
  }
}
