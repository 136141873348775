.ProjectPicker {
  &-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .ant-btn {
      border-color: #f5f5f7;
      border-radius: 20px;
      color: #cfced4;
      margin: 5px;

      &:hover {
        color: black;
      }
    }

    .ant-btn-selected {
      background: var(--theme-primary);
      border-color: var(--theme-primary);
      color: white;

      &:hover {
        color: white;
      }
    }
  }

  &-more-btn {
    border: unset;
    box-shadow: none;
    color: black !important;

    &:hover {
      color: var(--theme-primary);
    }
  }

  &-empty-projects {
    color: rgb(184, 184, 184);
    display: flex;
    font-weight: 300;
    justify-content: flex-start;
    margin: 10px 5px;
    width: 100%;
  }
}
