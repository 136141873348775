.TaskShare {
  &-sharetask_wrapper {
    .ant-divider-horizontal {
      margin: 4px 0px;
    }
    &-title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      color: #4b607c;
    }
    &-email {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      border: none;
      background-color: #f1f2f6;
      width: 100%;
      color: #c1c1c1;

      &:focus {
        outline: none;
        box-shadow: unset;
      }
      /* identical to box height, or 162% */
    }
    &-share {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;

      color: #000000;
      margin-top: 20px;

      opacity: 0.7;
    }
  }
}
