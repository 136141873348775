@import '~react-calendar/dist/Calendar.css';

.react-calendar {
  border: unset;
  padding: 0 10px;

  .react-calendar__month-view button {
    border-radius: 50%;
    font-size: 0.75rem;
  }
}

.react-calendar__navigation {
  display: flex;
  height: 30px;
  justify-content: space-between;
  margin: 5px 0;

  .react-calendar__navigation__label {
    border-radius: 5px;
    color: #bcbac8;
    margin: 0 5px;

    &:hover {
      background: inherit !important;
      color: var(--theme-primary);
    }
  }

  .react-calendar__navigation__arrow {
    border-radius: 50%;
    color: #afafaf;
    font-size: 1rem;
    min-width: 30px;
    width: 30px;
  }

  button:disabled {
    opacity: 0;
  }

  button:hover {
    background-color: var(--theme-primary) !important;
    color: white;
  }
}

.react-calendar__tile:disabled {
  background: inherit;
  border-radius: 0;
  color: rgb(209, 209, 209);
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  &:hover {
    background-color: #dedde5;
  }
}

.react-calendar__month-view__weekdays__weekday abbr {
  cursor: default;
  font-size: 0.7rem;
  font-weight: 600;
  text-decoration: none;
}

.react-calendar__tile--active {
  background: var(--theme-primary) !important;
  color: white;
}

.react-calendar__tile .react-calendar__tile--hasActive {
  color: white !important;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}
