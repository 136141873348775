.SettingsDropdown {
  &-container {
    align-items: center;
    display: flex;
    margin: 15px 0;
  }

  &-fieldname {
    min-width: 120px;
    width: 100%;

    @media screen and (min-width: 650px) {
      min-width: 250px;
      width: auto;
    }
  }

  &-dropdown {
    min-width: 150px;
    width: 100%;

    @media screen and (min-width: 650px) {
      min-width: 250px;
      width: auto;
    }
  }

  &-option {
    margin: 0 15px;
  }
}
