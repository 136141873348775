.TodoTaskProjectTag {
  &-project {
    background-color: white;
    border: 1px solid #dedede;
    border-radius: 3px;
    color: var(--theme-primary);
    cursor: pointer;
    font-size: 0.6rem;
    font-weight: 600;
    margin-bottom: 1px;
    margin-right: 12px;
    padding: 1px 5px;
  }
}
