.RecurrenceMonthPicker {
  &-add-div {
    border: 1px solid var(--theme-secondary-light) !important;
    border-radius: 0 5px 0 0 !important;
    color: var(--theme-secondary-main);
    height: 50% !important;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;

    i {
      font-size: 10px !important;
    }
  }

  &-minus-div {
    border: 1px solid var(--theme-secondary-light) !important;
    border-radius: 0 0 5px !important;
    bottom: 0;
    color: var(--theme-secondary-main);
    height: 50% !important;
    opacity: 0;
    position: absolute;
    right: 0;

    i {
      font-size: 10px !important;
    }

    &:focus,
    &:hover {
      color: var(--theme-delete-main);
    }
  }

  &-row {
    align-items: center;
    background: var(--theme-secondary-light);
    border-radius: 5px;
    display: flex;
    margin: 7px;
    padding: 8px 5px;
    position: relative;

    &:hover .RecurrenceMonthPicker-add-div,
    &:hover .RecurrenceMonthPicker-minus-div {
      opacity: 1;
    }
  }

  &-label {
    color: var(--theme-primary-dark) !important;
    font-weight: 500;
    min-width: 100px;
    text-transform: capitalize !important;

    .ant-select {
      font-size: 0.8rem !important;
      width: auto;
    }

    .ant-select-selector {
      background: inherit !important;
      border: unset !important;
      box-shadow: unset !important;
    }

    .ant-select-arrow {
      color: var(--theme-secondary-dark);
      transform: translateY(-3px);
    }

    .ant-select-selection-placeholder {
      color: var(--theme-secondary-dark);
      opacity: 1;
    }

    .ant-select-selection-item {
      color: var(--theme-secondary-dark);
    }
  }

  &-month-dropdown {
    min-width: 110px !important;
  }

  &-month-option {
    margin: 0;
    text-transform: capitalize;

    .ant-select-item-option-content {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &.ant-select-item-option-selected {
      background: var(--theme-secondary-light);
      color: var(--theme-primary) !important;
      font-weight: 600;
    }
  }

  &-time-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: 22px;
    width: 340px;

    .MomentTimePicker-container {
      margin: 0 5px 5px 0;
    }
  }

  &-plus {
    background: inherit !important;
    border: unset !important;
    color: var(--theme-secondary-main);
    outline: none !important;

    &:focus {
      outline: none !important;
    }
  }

  &-unit {
    .ant-select-selection-item i {
      display: none;
    }
  }
}
