.AttachmentInputView {
  &-container {
    align-items: center;
    background-color: white;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    font-size: 0.8rem;
    margin: 5px 0;
    padding: 10px 15px;
    width: 100%;

    i {
      color: #c0c0cc;
    }
  }

  &-title {
    flex-grow: 1;
    margin: 0 15px;
  }

  &-delete {
    cursor: pointer;

    &:hover {
      color: #e6647a;
    }
  }
}
