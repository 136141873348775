.MeetingEventTitle {
  &-container {
    align-items: center;
    display: flex;
  }

  &-icon {
    font-size: 1.75rem;
    margin-right: 15px;
  }

  &-title-container {
    display: flex;
    flex-direction: column;
  }

  &-title {
    font-size: 1rem;
    font-weight: 600;
  }

  &-subtitle {
    color: var(--theme-secondary-main);
    font-size: 0.8rem;
  }
}
