.UpcomingDayBody {
  &-add-task {
    bottom: 5px;
    color: var(--theme-secondary-main);
    cursor: pointer;
    left: 72px;
    opacity: 0;
    position: absolute;

    i {
      font-size: 10px;
      margin-right: 3px;
    }

    &:hover {
      color: var(--theme-primary);
    }
  }
}
