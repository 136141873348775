.TodoTodayContent {
  &-title {
    margin: 15px 0;

    &-container {
      align-items: center;
      display: flex;
      font-size: 1.05rem;
      justify-content: space-between;
      margin-bottom: 25px;

      h2 {
        color: var(--theme-primary);
      }
    }
  }

  &-today-title {
    flex-grow: 1;
  }

  &-date-title {
    color: #b9b9b9;
    font-size: 0.9rem;
    margin-left: 7px;
  }

  /** Adds a bottom padding and minimum height */
  &-container {
    min-height: 700px;
    padding-bottom: 40px;
    width: 300px;

    @media screen and (min-width: 380px) {
      width: 350px;
    }

    @media screen and (min-width: 580px) {
      width: 500px;
    }

    @media screen and (min-width: 800px) {
      width: 450px;
    }

    @media screen and (min-width: 900px) {
      width: 550px;
    }

    @media screen and (min-width: 1200px) {
      width: 750px;
    }

    .rst__node:hover .rst__moveHandle {
      height: 0 !important;
    }
  }

  &-overdue-section-title {
    color: #fe4b87;
    font-weight: 600;
    margin: 0 0 12px 3px;
  }

  &-today-section-title {
    color: var(--theme-primary);
    font-weight: 600;
    margin: 25px 0 12px 3px;
  }

  &-sort-order {
    display: none;
  }

  &-sort-order-enabled {
    cursor: pointer;
    display: block;

    &:hover {
      color: var(--theme-primary);
    }
  }

  &-sort-dropdown {
    width: 160px !important;

    .ant-select-item {
      color: var(--theme-secondary-dark);
      font-size: 0.9rem;
      text-transform: capitalize;
    }

    .ant-select-item-option-selected {
      background-color: inherit;
      color: black;
      font-weight: 600;
    }
  }

  &-sort-container {
    align-items: center;
    color: var(--theme-secondary-dark);
    display: flex;
    font-size: 0.8rem;
    margin: 0 0 0 10px;
    text-transform: uppercase;

    .ant-select-selector {
      color: var(--theme-secondary-dark);
      font-size: 0.7rem;
      padding: 0 8px !important;
    }

    .ant-select-arrow {
      top: 38%;
    }

    i {
      font-size: 0.9rem;
    }
  }
}
