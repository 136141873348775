.SharedTask {
  width: 100%;
  padding: 0px 70px;

  &-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    color: #3161f1;
  }
  &-overdue {
    min-height: 200px;
    margin-top: 50px;
    &-text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #f1313d;
    }
  }
  &-todo {
    min-height: 200px;
    margin-top: 50px;
    &-text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #3161f1;
    }
  }
  &-completed {
    min-height: 200px;
    margin-top: 50px;
    &-text {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      color: #bbbac7;
    }
  }
}
@media screen and (min-width: 380px){
  .SharedTask {
      width: 350px;
  }
}
@media screen and (min-width: 580px) {
  .SharedTask {
      width: 500px;
  }
}

@media screen and (min-width: 800px){
  .SharedTask {
      width: 450px;
  }
}
@media screen and (min-width: 900px) {
  .SharedTask {
      width: 650px;
  }
}
@media screen and (min-width: 1200px){
  .SharedTask {
      width: 850px;
  }
}
