.ProjectTasksList {
  &-container {
    .rst__moveHandle,
    .rst__loadingHandle {
      left: 0;
      margin-top: 0;
      position: absolute;
      top: 50%;
      transform: translate(-15px, -50%) !important;
    }

    .rst__collapseButton,
    .rst__expandButton {
      display: none;
      margin-top: 8px;
    }

    .TaskViewItem-project {
      display: none;
    }
  }

  &-completed-title {
    color: #bcb9c4;
    font-size: 0.9rem;
    font-weight: 500;
    margin: 12px 0;
  }

  &-completed-section {
    margin-left: 14px;

    .rst__moveHandle {
      display: none;
    }

    .rst__collapseButton,
    .rst__expandButton {
      transform: translateX(28px);
    }
  }

  &-completed-container {
    margin: 5px 0 10px 15px;
  }
}
