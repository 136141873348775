.NotificationsDropdown {
  &-container {
    background: white;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    transform: translate(1px, 8px);
    width: 350px;

    &-hidden {
      display: none;
    }
  }

  &-header {
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    font-size: 0.9rem;
    justify-content: space-between;
    padding: 10px 20px;
  }

  &-mark-all {
    color: #bebccd;
    cursor: pointer;
    font-weight: 300;

    &:hover {
      text-decoration: underline;
    }
  }

  &-title {
    color: #424242;
    font-weight: 500;
  }

  &-body {
    max-height: 400px;
    overflow: auto;

    &-empty {
      align-items: center;
      color: #e0e0e0;
      display: flex;
      font-weight: 300px;
      height: 80px;
      justify-content: center;
      width: 100%;
    }
  }
}
