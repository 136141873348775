.TodoDetailedTaskBreadcrumb {
  &-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    width: 660px;

    a {
      color: #707070;

      &:hover {
        color: var(--theme-primary);
      }
    }
  }

  &-project {
    align-items: center;
    display: flex;
  }

  &-nav-item {
    color: #707070;
    cursor: pointer;
    font-size: 0.7rem;
    margin: 0 10px;
    margin-top: 1px;
    max-width: 85px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    i {
      color: var(--theme-primary);
      font-size: 0.6rem;
      margin-right: 3px;
    }

    &:hover {
      color: var(--theme-primary);
    }
  }

  &-nav-arrow i {
    color: #b9b9b9;
    font-size: 0.6rem;
  }

  &-dot {
    border-radius: 50%;
    height: 7px;
    margin-right: 5px;
    margin-top: 1px;
    width: 7px;
  }

  &-project-title {
    max-width: 72px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
