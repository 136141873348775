.Meeting {
  &-container {
    background-color: #f9f9f9;
    width: 100%;
  }

  &-header {
    background-color: white;
    box-shadow: 0 0 8px 2px #444;
    box-shadow: 0 13px 15px -8px #eee;
    display: flex;
    justify-content: center;
    min-height: 10px;
  }

  &-body {
    background-color: #f9f9f9;
    display: flex;
    flex-grow: 1;
    justify-content: center;

    .ant-list {
      width: 100%;
    }

    &-header {
      display: flex;
      margin: 20px 0;

      .ant-input-affix-wrapper {
        border-radius: 5px;
        width: 180px;

        i {
          color: var(--theme-secondary-main);
        }
      }

      .ant-radio-button-wrapper {
        height: 45px;
        padding-top: 7px;
      }

      .ant-radio-button-wrapper-checked {
        background-color: var(--theme-primary);
        color: white;
      }
    }

    &-body {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  &-inner {
    padding-bottom: 40px;
    width: 300px;

    @media screen and (min-width: 380px) {
      width: 350px;
    }

    @media screen and (min-width: 580px) {
      width: 500px;
    }

    @media screen and (min-width: 800px) {
      width: 450px;
    }

    @media screen and (min-width: 900px) {
      width: 550px;
    }

    @media screen and (min-width: 1200px) {
      width: 750px;
    }
  }

  &-title {
    margin: 15px 0;

    &-container {
      align-items: center;
      display: flex;
      font-size: 1.05rem;
      justify-content: space-between;
      margin-bottom: 5px;

      h2 {
        color: var(--theme-primary);
      }
    }
  }

  &-create-btn {
    color: white;
  }

  &-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-radio-group {
    margin-right: 10px;
  }

  &-range-picker {
    height: 45px;
    margin-right: 20px;

    .ant-picker-suffix {
      display: none;
    }
  }

  &-space {
    flex-grow: 1;
  }

  &-radio {
    &-first {
      border-radius: 5px 0 0 5px !important;
    }

    &-last {
      border-radius: 0 5px 5px 0 !important;
    }
  }
}
