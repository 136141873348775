.LabelColorPicker {
  &-container {
    align-items: center;
    display: flex;
    justify-content: space-around;

    .ant-checkbox-inner {
      background-color: var(--cp-background) !important;
      border-color: var(--cp-background) !important;
      border-radius: 0;
      height: 20px;
      padding: 5px 10px 5px 0;
      width: 20px;
    }

    .ant-checkbox {
      &:hover,
      &:focus,
      &::after {
        border-color: var(--cp-background) !important;
        border-radius: 50% !important;
      }
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin: 2px;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      left: 2px;
    }
  }

  &-more-btn {
    background-color: inherit;
    border: unset;
    box-shadow: none;
    color: gray;
  }

  &-chrome-picker {
    cursor: pointer;
  }

  &-triangle {
    border-bottom: 10px solid transparent;
    border-right: 10px solid var(--cp-background);
    border-top: 10px solid transparent;
    height: 0;
    width: 0;
  }

  &-obj-container {
    align-items: center;
    display: flex;
  }
}
