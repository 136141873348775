.MeetingWorkflowItem {
  &-container {
    background-color: white;
    border-radius: 7px;
  }

  &-header {
    border-bottom: 1px solid var(--theme-secondary-light);
    padding: 10px 25px;
  }

  &-body {
    padding: 20px 25px 40px;
  }

  &-field-container {
    margin-bottom: 25px;
    max-width: 450px;
  }

  &-shared-field-container {
    display: flex;
    margin-bottom: 25px;
    max-width: 450px;

    .ant-select {
      border-radius: 5px;
      width: 120px;
    }

    .ant-select-selector {
      min-height: 45px !important;
      padding: 7px 15px !important;
    }

    .ant-select-item {
      margin: 0 !important;
    }

    .ant-select-item-option {
      margin: 0 !important;
      padding: 12px 15px;
    }

    .ant-select-item-option-selected {
      background-color: var(--theme-hover-light);
      color: black;
    }
  }

  &-field-label {
    color: black;
    font-weight: 600;
    margin-bottom: 7px;
  }

  &-field-body {
    .ant-divider-horizontal {
      border-color: white;
      margin: 0;
    }

    .TodoShareUserItem-user-item {
      padding: 5px 0;
    }

    input {
      border-radius: 5px;
      padding: 10px 15px;
    }

    .ant-select {
      border-radius: 5px;
      width: 100%;
    }

    .ant-select-selector {
      min-height: 45px !important;
      padding: 7px 15px !important;
    }

    .ant-select-item {
      margin: 0 !important;
    }

    .ant-select-item-option {
      margin: 0 !important;
      padding: 12px 15px;
    }

    .ant-select-item-option-selected {
      background-color: var(--theme-hover-light);
      color: black;
    }

    i,
    .MeetingEvent-icon {
      margin-right: 10px;
    }

    .rdw-editor-wrapper {
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      padding: 10px;

      &:focus-within {
        border-color: var(--theme-light);
        box-shadow: 0 0 0 2px #1890ff33;
      }

      .rdw-editor-toolbar {
        border: unset;
      }

      .rdw-option-wrapper {
        border: unset;
        box-shadow: none;
        opacity: 0.6;
        padding: 0 10px 0 0;

        &:hover {
          color: var(--theme-primary);
        }
      }
    }

    .public-DraftEditor-content {
      box-shadow: unset !important;
      color: black !important;
      min-height: 110px;
      outline: none !important;
      padding: 0 10px;
    }

    .public-DraftEditorPlaceholder-root {
      color: #bfbfbf;
      padding: 0 10px;
    }

    .rdw-link-modal {
      border-color: var(--theme-secondary-light);
      border-radius: 5px;
      box-shadow: none;
      color: gray;
      height: 250px;

      button {
        border-radius: 5px;
        box-shadow: none;

        &:focus {
          background: var(--theme-dark);
          border-color: var(--theme-dark);
          color: white;
        }

        &:disabled {
          background: var(--theme-disabled) !important;
          border-color: var(--theme-disabled) !important;
          color: white !important;
        }

        &:hover {
          background: var(--theme-light);
          border-color: var(--theme-light);
          color: white;
        }
      }
    }
  }

  &-send-btn {
    border-radius: 5px;

    &:focus {
      background: var(--theme-dark);
      border-color: var(--theme-dark);
      color: white;
    }

    &:disabled {
      background: var(--theme-disabled) !important;
      border-color: var(--theme-disabled) !important;
      color: white !important;
    }

    &:hover {
      background: var(--theme-light);
      border-color: var(--theme-light);
      color: white;
    }
  }

  &-field-relative {
    .ant-select {
      margin-right: 20px;
      width: 170px;
    }
  }
}
