.MomentTimePicker {
  &-container {
    position: relative;
    width: 100px;

    .TimePicker-date {
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      padding: 2px;
      padding-right: 25px;
      width: 100%;
    }

    &:hover .MomentTimePicker-clear {
      display: block !important;
    }

    &:hover .MomentTimePicker-clock {
      display: none !important;
    }

    &:hover .MomentTimePicker-clear-btn {
      background: var(--theme-secondary-light);
    }
  }

  &-clear {
    display: none !important;
    font-size: 10px;

    &:hover {
      color: var(--theme-delete-main);
    }
  }

  &-clock {
    display: block !important;
  }

  &-clear-btn {
    border-radius: 50%;
    color: var(--theme-secondary-main);
    cursor: pointer;
    font-size: 12px;
    height: 18px;
    padding: 3px;
    position: absolute;
    right: 8px;
    text-align: center;
    top: 6px;
    width: 18px;

    &:hover {
      background: var(--theme-delete-main) !important;

      .MomentTimePicker-clear {
        color: white !important;
      }
    }
  }
}
