.TodoProjectDeleteButton {
  &-modal-title {
    color: #fe4b87;
  }

  &-infoModal-title {
    color: var(--theme-primary);
  }

  &-infoModal-container {
    .anticon {
      color: #fd8a1e !important;
    }

    .ant-btn {
      border-radius: 5px;
    }

    .ant-btn-primary {
      background: var(--theme-primary);
      border-color: var(--theme-primary);
      color: white;

      &:hover {
        background: var(--theme-light);
        border-color: var(--theme-light);
        color: white;
      }
    }
  }

  &-modal-container {
    .anticon {
      color: #fe4b87 !important;
    }

    .ant-btn {
      border-radius: 5px;
    }

    /* stylelint-disable no-descending-specificity */
    .ant-btn-primary {
      background: #fe4b87;
      border-color: #fe4b87;
      color: white;

      &:hover {
        background: #f50756;
        border-color: #f50756;
        color: white;
      }
    }
    /* stylelint-enable no-descending-specificity */
  }
}
