.TodoCalendarPicker {
  &-container {
    background-color: white;
    border: 1px solid #e3e2eb;
    border-radius: 5px;
    padding: 5px 0 7px;
    width: 240px;

    .UpcomingBadge-container {
      width: 80px;
    }

    .ant-divider {
      margin: 3px 0;
    }

    .react-calendar__tile {
      font-size: 0.65rem !important;
      min-height: 30px;
      min-width: 30px;
    }

    .react-calendar__month-view__weekdays__weekday abbr {
      font-size: 0.55rem !important;
    }

    .react-calendar__navigation {
      margin-bottom: 5px;
      min-height: 30px;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: #a5a5a5 !important;
    }

    .react-calendar__tile--active {
      color: white !important;
    }

    .react-calendar__tile:disabled {
      color: #e7e7e7 !important;
    }
  }

  &-quick {
    &-container {
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      font-size: 0.8rem;
      margin: 2px 10px;
      padding: 3px 7px;

      &:hover {
        background-color: rgb(235, 235, 235);
      }
    }

    &-label {
      flex-grow: 1;
      font-weight: 600;
      margin: 0 10px;
    }

    &-day {
      color: #bcbac8;
      font-size: 0.65rem;
    }
  }

  &-actions-container {
    background-color: inherit;
    border: unset;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px 30px 5px;
    padding: 0;
    width: 80%;
  }

  &-calendar {
    font-size: 0.7rem;
    padding: 0 3px 5px;
  }

  &-time-set-btn {
    background: unset !important;
    cursor: unset !important;
  }

  &-set-btn {
    border: 0.5px solid #e3e2eb;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    font-size: 0.7rem;
    justify-content: space-evenly;
    margin: 0 30px;
    padding: 5px;
    text-align: center;
    width: 80%;

    input {
      border: unset;
      color: var(--theme-primary);
      width: 75px;

      &:hover {
        background-color: rgb(235, 235, 235);
      }
    }

    &:hover {
      background-color: rgb(235, 235, 235);
    }

    &.ant-dropdown-open {
      border-color: var(--theme-primary);
      color: var(--theme-primary);
    }
  }

  &-cancel-btn {
    background-color: rgb(243, 243, 243) !important;
    border: 0.5px solid #e3e2eb;
    border-radius: 5px;
    cursor: pointer;
    flex-grow: 1;
    font-size: 0.7rem;
    margin: 0;
    margin-right: 5px;
    padding: 7px;
    text-align: center;
    width: 50px;
  }

  &-done-btn {
    background-color: var(--theme-primary) !important;
    border: 1px solid gray;
    border: 0.5px solid var(--theme-primary);
    border-radius: 5px;
    color: white;
    cursor: pointer;
    flex-grow: 1;
    font-size: 0.7rem;
    margin: 0;
    margin-left: 5px;
    padding: 7px;
    text-align: center;
    width: 50px;

    &:hover {
      background-color: var(--theme-dark) !important;
    }
  }

  &-today-icon {
    color: var(--theme-task-today);
  }

  &-tomorrow-icon {
    color: var(--theme-task-tomorrow);
  }

  &-next-week-icon {
    color: var(--theme-task-next-week);
  }

  &-no-date-icon {
    color: #ff6c6c;
  }

  &-sunday,
  &-monday,
  &-tuesday,
  &-wednesday,
  &-thursday,
  &-friday,
  &-saturday {
    color: #6e6e6e;
  }

  &-confirm-modal {
    p {
      color: gray;
      font-weight: 600;
    }

    .ant-modal-body {
      background-color: white;
      color: black;
      font-weight: 600;
    }

    .ant-btn {
      background: unset;
      border: unset !important;
      border-radius: 5px;
      box-shadow: unset !important;
      color: var(--theme-secondary-dark);
      outline: unset !important;

      &:hover {
        background: var(--theme-secondary-light) !important;
      }
    }

    .ant-btn-primary {
      color: var(--theme-primary);
    }
  }
}
