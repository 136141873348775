.TaskViewItem {
  &-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-right: 15px;
    text-align: justify;

    @media screen and (min-width: 1000px) {
      flex-direction: row;
      justify-content: space-between;
      padding-right: 0;
    }

    &:first-of-type {
      margin-bottom: 2px;
      margin-top: 0;
      padding-top: 12px;
    }
  }

  &-container-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: 1000px) {
      align-items: center;
      flex-direction: row;
      padding-right: 0;
    }
  }

  &-meta-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  &-reminders {
    color: #bbbac8;
    font-size: 0.8rem;
    margin-right: 12px;
    margin-top: 1px;

    .ant-tooltip {
      border-radius: 5px;
    }

    i {
      font-size: 0.7rem;
    }

    &-count {
      color: var(--theme-primary);
      margin-left: 4px;
    }
  }

  &-comments {
    color: #bbbac8;
    font-size: 0.8rem;
    margin-right: 12px;
    margin-top: 1px;

    .ant-tooltip {
      border-radius: 5px;
    }

    i {
      font-size: 0.7rem;
    }

    &-count {
      color: var(--theme-primary);
      margin-left: 4px;
    }
  }

  &-due {
    display: block;
    font-size: 0.8rem;
  }

  &-date-container &-due {
    margin-top: 2px;
  }

  &-date-container {
    align-items: center;
    display: flex;
    margin: 0 2px 0 0;
    min-width: 165px;
    text-align: right;

    @media screen and (min-width: 1000px) {
      justify-content: flex-end;
    }
  }

  &-user-plus {
    color: #bbbac8;
    cursor: pointer;
    font-size: 0.8rem;
    margin-right: 11px;

    .ant-avatar-sm {
      height: 18px;
      line-height: 18px;
      margin-bottom: 3px;
      width: 18px;
    }

    &:hover {
      color: var(--theme-primary);
    }
  }

  &-hover-due {
    display: none;
    font-size: 0.8rem;
  }

  &-reminders-tooltip {
    border-radius: 15px !important;
    font-size: 0.7rem;
    width: 220px;

    .TaskReminderViewItem-container {
      background: inherit;
      border: unset;
      margin: 3px;
    }

    .TaskReminderViewItem-date {
      color: white;
    }

    .TaskReminderViewItem-history {
      background: inherit;
      border: unset;
      color: white;
    }

    .TaskReminderViewItem-delete {
      display: none;
    }
  }

  &-recurring {
    color: #bbbac8;
    font-size: 0.8rem;
    margin-right: 12px;
    margin-top: 2px;

    .ant-tooltip {
      border-radius: 5px;
    }

    i {
      font-size: 0.8rem;
    }
  }
}
