.MeetingCard {
  &-container {
    align-items: center;
    display: flex;
    min-width: 180px;

    .ant-statistic-content-suffix {
      color: var(--theme-secondary-main);
      font-size: 0.9rem;
    }

    .ant-statistic-title {
      color: black;
      font-size: 1rem;
    }

    i {
      font-size: 2.5rem;
      margin-right: 15px;
    }
  }

  &-card {
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 5px 0, rgba(0, 0, 0, 0.1) 0 0 1px 0;
  }
}
