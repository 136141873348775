.Projects {
  &-add-container,
  &-empty-container {
    align-items: center;
    color: #5a595e;
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  &-add-btn {
    background: var(--theme-primary);
    border: unset;
    border-radius: 5px;
    box-shadow: none;
    color: white;
    font-size: 0.6rem;
    text-transform: uppercase;

    i {
      margin-right: 5px;
    }

    &:hover {
      background: var(--theme-light);
      border: unset;
      color: white;
    }

    &:focus {
      background: var(--theme-dark);
      border: unset;
      color: white;
    }
  }
}
