.AttachmentItem {
  &-container {
    margin: 0 50px 20px 0;
    position: relative;

    .ant-image-error {
      align-items: center;
      display: flex;
      justify-content: center;

      .ant-image-img {
        width: 30%;
      }
    }
  }

  &-pic-body {
    background-color: var(--theme-secondary-light);
    border: 1px solid var(--theme-secondary-light);
    border-radius: 5px;
    height: 100px;
    margin-bottom: 10px;
    overflow: hidden;
    width: 150px;
  }

  &-title {
    color: black;
    font-weight: 500;

    &:hover {
      color: var(--theme-primary);
      text-decoration: underline;
    }
  }

  &-size {
    color: var(--theme-secondary-main);
  }

  &-delete {
    color: var(--theme-secondary-main);
    position: absolute;
    right: 0;
    transform: translate(0, -50%);
    z-index: 1;
  }
}
