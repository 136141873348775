.MenuMorePanel {
  &-panel-container {
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    min-width: 240px !important;
    padding: 2px 0;
    transform: translate(1px, 8px);
  }

  &-panel-container .ant-menu-item {
    border-radius: 5px;
    font-size: 0.8rem;
    margin: 0;
    margin-bottom: 0 !important;
    padding: 0 25px;

    &:hover {
      background-color: #f9f9f9;
    }

    .UpcomingBadge-container {
      line-height: 5px;
      margin-top: 5px;
      padding-top: 5px;
      position: absolute;
      right: 20px;
      top: 5px;
      z-index: 100;

      i {
        margin: 0;
        padding: 0;
      }
    }
  }

  &-apply-container {
    align-items: center;
    display: flex;
    justify-content: space-around;
    padding: 0 5px;

    .ant-btn {
      background-color: transparent;
      border: unset;
      box-shadow: none !important;
      padding: none !important;
      transform: scale(1.5);
    }
  }
}
