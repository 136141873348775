.TodoPriorityDropdown {
  &-container {
    background-color: white;
    border: 1px solid #e3e2eb;
    border-radius: 5px;
    min-width: 200px;
    padding: 10px 0;
  }

  &-priority-item {
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    font-size: 0.8rem;
    margin: 1px 10px;
    padding: 3px 5px;

    &-selected {
      background-color: #f9f9f9;
    }

    &-label {
      flex-grow: 1;
      pointer-events: none;
    }

    .anticon {
      color: var(--theme-primary);
    }

    &:hover {
      background-color: #f9f9f9;
    }
  }

  &-box {
    height: 14px;
    margin: 2px 10px 0 0;
    pointer-events: none;
    width: 14px;
  }
}
