.TodoFavourites {
  &-container {
    max-width: 285px;
    padding-left: 15px;
  }

  &-project-dot {
    border-radius: 50%;
    display: inline-block;
    height: 10px !important;
    margin: 8px 3px 0 2px;
    width: 10px;
  }

  &-tag {
    margin-top: 5px;
    width: 15px;
  }

  &-drop {
    margin-top: 5px;
    width: 15px;
  }
}
