.SharedTaskItem {
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(150, 150, 150, 0.16);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
  margin-top: 13px;
  &-title {
    font-family: 'Roboto', sans-serif;
  }
  &-checkbox_wrapper {
    display: flex;
    gap: 23px;
    align-items: center;
  }
  &-date_wrapper {
    display: flex;
    gap: 22px;

    &-time{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
    } 
  }
  &-due{
    color: #F1313D;
  }
  &-todo{
    color: #3161F1;
  }
  &-completed{
    color: #BBBAC7;
  }
}
.task_meta_container{
  display: flex;
  gap: 16px;
  margin-top: 10px;
}
