.RecurrenceYearPicker {
  &-container {
    .react-datepicker__day {
      border-radius: 50% !important;
      color: var(--theme-secondary-dark);
    }

    .react-datepicker__day--selected {
      background: var(--theme-primary);
      color: white;
    }

    .react-datepicker__day--keyboard-selected {
      background: unset;
      border: 1px solid var(--theme-primary);
    }

    .react-datepicker__day--disabled {
      color: var(--theme-secondary-light) !important;
    }
  }

  &-add-div {
    border: 1px solid var(--theme-secondary-light) !important;
    border-radius: 0 5px 0 0 !important;
    color: var(--theme-secondary-main);
    height: 50% !important;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;

    i {
      font-size: 10px !important;
    }
  }

  &-minus-div {
    border: 1px solid var(--theme-secondary-light) !important;
    border-radius: 0 0 5px !important;
    bottom: 0;
    color: var(--theme-secondary-main);
    height: 50% !important;
    opacity: 0;
    position: absolute;
    right: 0;

    i {
      font-size: 10px !important;
    }

    &:focus,
    &:hover {
      color: var(--theme-delete-main);
    }
  }

  &-row {
    align-items: center;
    background: var(--theme-secondary-light);
    border-radius: 5px;
    display: flex;
    margin: 7px;
    min-height: 50px;
    padding: 8px 5px;
    position: relative;

    .react-datepicker__day--outside-month {
      display: none;
    }

    .react-datepicker__week {
      all: unset;
    }

    .react-datepicker__month-container {
      width: 250px;
    }

    .react-datepicker__day-names {
      display: none;
    }

    .react-datepicker__header {
      background: white;
      border: unset;
      margin: 10px 0;
    }

    &:hover .RecurrenceYearPicker-add-div,
    &:hover .RecurrenceYearPicker-minus-div {
      opacity: 1;
    }
  }

  &-label {
    color: var(--theme-secondary-dark);
    font-weight: 500;
    min-width: 100px;
    position: relative;
    text-transform: capitalize !important;

    .ant-select {
      font-size: 0.8rem !important;
      width: auto;
    }

    .ant-select-selector {
      background: inherit !important;
      border: unset !important;
      box-shadow: unset !important;
    }
  }

  &-month-dropdown {
    min-width: 110px !important;
  }

  &-time-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: 22px;
    width: 340px;

    .MomentTimePicker-container {
      margin: 0 5px 5px 0;
    }
  }

  &-plus {
    background: inherit !important;
    border: unset !important;
    color: var(--theme-secondary-main);
    outline: none !important;

    &:focus {
      outline: none !important;
    }
  }

  &-input {
    align-items: center;
    cursor: pointer;
    font-size: 0.8rem;
    margin-left: 9px;

    i {
      transform: translateY(-2px);
    }
  }

  &-header {
    align-items: center;
    display: flex;
    justify-content: space-around;
    margin: 0 0 5px;
    width: 100%;

    &-label {
      color: var(--theme-secondary-main);
      font-size: 0.9rem;
    }

    button {
      background: inherit;
      border: unset;
      color: var(--theme-secondary-main);
      cursor: pointer;
      font-size: 0.7rem;

      &:disabled {
        visibility: hidden;
      }
    }
  }
}
