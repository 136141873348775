.MeetingWorkflow {
  &-container {
    .ant-collapse-item {
      background: white;
      border: 1px solid var(--theme-secondary-light);
      border-radius: 5px;
      margin-bottom: 20px;
    }

    .ant-collapse-content {
      background: none;
      border: unset !important;
    }

    .ant-collapse-header {
      background: none;
      border-bottom: 1px solid var(--theme-secondary-light);
    }

    .ant-collapse {
      border: unset;
    }
  }
}
