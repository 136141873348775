.TodoFavouritesItem {
  &-label {
    color: #161616;
    flex-grow: 1;
    font-weight: 350;
    margin: 0 15px 0 10px;
  }

  &-container {
    border-radius: 5px;
    color: var(--theme-secondary-main);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-weight: 300;
    margin: 0 15px 2px 20px;
    padding: 5px;

    &--active {
      background: #e3e5eb !important;
      color: var(--theme-primary) !important;

      .TodoFavouritesItem-label {
        font-weight: 500;
      }
    }

    &:hover {
      background: #f5f5fa;
    }
  }

  &-more-btn {
    background: none !important;
    border: unset;
    box-shadow: none;
    color: #b9b6c6;
    font-size: 0.7rem;

    &-container {
      display: none;
      left: 264px;
      position: absolute;

      &--active {
        display: block !important;
      }

      .TodoFavouritesItem-container:hover & {
        display: block;
      }
    }
  }

  &-share {
    color: var(--theme-secondary-main);
    font-size: 0.8rem;
    margin-left: 8px;
  }
}
