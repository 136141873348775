.TodoDetailedTask {
  &-task-head {
    background: white;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    font-size: 0.95rem;
    font-weight: 600;
    padding: 5px 20px 10px;
    position: relative;

    &-edit {
      background-color: inherit;
      padding: 5px 0 10px;

      .AddTask-input-container {
        background: #f9f9f9;
        border-color: #efefef;
        padding-left: 30px;

        .ant-input {
          background-color: #f9f9f9 !important;
          font-size: 0.95rem;
          font-weight: 700;
        }

        .AddTask-calendar-container {
          border-left: 1px solid #c5c4cb;
        }
      }
    }

    &-checkbox {
      left: 14px;
      position: absolute;
      top: calc(50% - 38px);
    }

    .TaskViewItem-edit {
      font-size: 0.8rem;
    }
  }

  &-container {
    position: relative;

    .ant-comment {
      .TodoCommentDelete-container {
        opacity: 0;
      }

      &:hover .TodoCommentDelete-container {
        opacity: 1;
      }
    }

    .ant-tabs-nav {
      background-color: white;
      box-shadow: rgba(33, 35, 38, 0.1) 0 10px 10px -10px;
      color: var(--theme-secondary-main);
      padding: 0 20px !important;

      @media screen and (min-width: 600px) {
        padding: 0 50px !important;
      }
    }

    .ant-tabs-tabpane {
      padding: 0 20px;

      @media screen and (min-width: 600px) {
        padding: 0 50px;
      }
    }

    .ant-tabs-tab {
      margin: 0 35px 0 0;
      padding: 10px 15px 10px 10px;

      &:hover {
        color: black;
      }
    }

    .ant-tabs-tab-btn:focus {
      box-shadow: none !important;
      outline: none !important;
    }

    .ant-tabs-content.ant-tabs-content-top,
    .ant-tabs.ant-tabs-top.ant-tabs-card,
    .ant-tabs-tabpane {
      &:focus {
        box-shadow: none !important;
        outline: none !important;
      }
    }

    .ant-tabs-nav-wrap::before {
      background-image: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0)) !important;
      box-shadow: none !important;
    }

    .ant-tabs-nav-wrap::after {
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255)) !important;
      box-shadow: none !important;
    }

    .TodoDetailedTask-task-head-edit .AddTask-input-container .ant-input {
      background: #f5f4f9;
    }

    .TodoDetailedTask-task-head {
      border: unset;
    }
  }

  &-task-body {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  &-main-body {
    width: 100%;
  }

  &-upper-body {
    background-color: white;
    padding: 0 20px;

    @media screen and (min-width: 600px) {
      padding: 0 50px;
    }
  }

  &-side-body {
    margin: 10px 0 20px;
    width: 125px;
  }

  &-details {
    &-container {
      padding: 0 0 30px;
    }

    &-title {
      font-size: 0.95rem;
      font-weight: 600;
    }

    &-body {
      border: 1px solid white;
      border-radius: 5px;
      color: #787878;
      font-size: 0.85rem;
      font-weight: 400;
      margin: 3px 5px 0;
      padding: 5px;
      transform: translate(-10px, 0);
      width: calc(100% + 20px);

      &:hover {
        background: #81818123;
      }

      &:focus {
        background: white;
        border: 1px solid var(--theme-focus);
        box-shadow: 0 0 0 2px #b4b4b433;
        outline: unset;
      }
    }

    &-block {
      align-items: center;
      display: flex;
      height: 100%;
      padding: 10px;
    }
  }

  &-subtasks {
    &-container {
      margin: 5px 0 20px;
    }

    &-add-subtask {
      color: var(--theme-primary);
      cursor: pointer;
      font-size: 0.8rem;
      font-weight: 300;
      margin-bottom: 15px;

      i {
        font-size: 0.6rem;
      }
    }

    &-title {
      align-items: center;
      display: flex;
      font-size: 0.95rem;
      font-weight: 600;
      justify-content: space-between;
    }

    &-body {
      margin: 10px 0 20px;

      .AddTask-input-container {
        margin-left: 2px;
      }
    }
  }

  &-attachment-tag {
    color: gray;
    cursor: pointer;
    font-size: 0.75rem;
    margin-top: 3px;

    i {
      color: var(--theme-primary);
      margin: 0 5px 0 0;
    }

    &:hover {
      color: var(--theme-primary);
    }

    a {
      all: unset;
    }
  }

  &-comments {
    &-title {
      color: var(--theme-primary);
      font-size: 0.95rem;
      font-weight: 600;
      margin: 30px 0 10px;
    }

    &-container {
      margin: 5px 0;
    }

    &-body {
      margin: 0 0 20px;

      .ant-comment-inner {
        padding: 10px 0;
      }

      .ant-avatar {
        font-size: 1rem;
        margin: 4px 5px 0 0;
        transform: scale(1.2);
      }

      .ant-comment-content-author {
        margin-bottom: 2;
      }
    }
  }

  &-comment-author {
    color: black !important;
    font-size: 0.9rem;
    font-weight: 500;
  }

  &-menu-btn {
    background-color: white;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.7rem;
    margin-bottom: 5px;
    padding: 7px 10px;

    i {
      color: gray;
      min-width: 20px;
    }

    &:hover {
      color: var(--theme-primary);

      i {
        color: var(--theme-primary);
      }
    }

    &--delete:hover {
      color: rgb(231, 112, 112);

      i {
        color: rgb(231, 112, 112);
      }
    }
  }

  &-empty-comments {
    background-color: none;
    color: gray;
    padding: 10px 0;
  }

  &-modal-container {
    .anticon {
      color: #fe4b87 !important;
    }

    .ant-btn {
      border-radius: 5px;
    }

    .ant-btn-primary {
      background: #fe4b87;
      border-color: #fe4b87;
      color: white;

      &:hover {
        background: #f50756;
        border-color: #f50756;
        color: white;
      }
    }
  }

  &-more-btn-container {
    position: absolute;
    right: 0;
    top: calc(50% - 38px);
    transform: translateX(25px);
  }

  &-options-btn {
    .TodoTaskOptionsPanel-item:first-child {
      display: none;
    }

    .ant-divider {
      display: none;
    }
  }

  &-add-comment {
    background-color: white;
    bottom: 0;
    height: 60px;
    padding: 10px 45px;
    position: fixed;
    right: 0;
    width: 100%;

    @media screen and (min-width: 400px) {
      width: 400px;
    }

    @media screen and (min-width: 600px) {
      width: 600px;
    }

    @media screen and (min-width: 800px) {
      width: 800px;
    }
  }

  &-not-found {
    animation: fadeAnimation 4s ease;
    margin-top: 70px;
  }

  &-not-found-text {
    color: var(--theme-secondary-main);
    font-size: 1rem;
    font-weight: 400;
    margin-top: 20px;
    text-align: center;
  }

  &-not-found-title {
    color: black;
    font-size: 1.3rem;
    margin-top: 30px;
    text-align: center;
  }
}

@keyframes fadeAnimation {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
