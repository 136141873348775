.TaskItem {
  &-container {
    background: #fcfcfc;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    color: #bbbbc7;
    cursor: pointer;
    margin-bottom: 6px;
    margin-left: 12px;
    min-height: 50px;
    padding: 4px 5px 5px 20px;
    transition: opacity 2s ease-in-out;

    &:hover {
      background: white;
    }

    &--first {
      margin-right: 2px;
      width: 300px;

      @media screen and (min-width: 380px) {
        width: 350px;
      }

      @media screen and (min-width: 580px) {
        width: 500px;
      }

      @media screen and (min-width: 800px) {
        width: 450px;
      }

      @media screen and (min-width: 900px) {
        width: 550px;
      }

      @media screen and (min-width: 1200px) {
        width: 752px;
      }
    }

    &--second {
      margin-right: 68px;
      width: 280px;

      @media screen and (min-width: 380px) {
        width: 330px;
      }

      @media screen and (min-width: 580px) {
        margin-right: 38px;
        width: 450px;
      }

      @media screen and (min-width: 800px) {
        width: 400px;
      }

      @media screen and (min-width: 900px) {
        width: 500px;
      }

      @media screen and (min-width: 1200px) {
        width: 700px;
      }
    }

    &--third {
      margin-right: 138px;
      width: 260px;

      @media screen and (min-width: 380px) {
        width: 310px;
      }

      @media screen and (min-width: 580px) {
        margin-right: 76px;
        width: 400px;
      }

      @media screen and (min-width: 800px) {
        width: 350px;
      }

      @media screen and (min-width: 900px) {
        width: 450px;
      }

      @media screen and (min-width: 1200px) {
        width: 650px;
      }
    }

    &--fourth {
      margin-right: 205px;
      width: 240px;

      @media screen and (min-width: 380px) {
        width: 290px;
      }

      @media screen and (min-width: 580px) {
        margin-right: 115px;
        width: 350px;
      }

      @media screen and (min-width: 800px) {
        width: 300px;
      }

      @media screen and (min-width: 900px) {
        width: 400px;
      }

      @media screen and (min-width: 1200px) {
        width: 600px;
      }
    }

    &--fifth {
      margin-right: 275px;
      width: 220px;

      @media screen and (min-width: 380px) {
        width: 270px;
      }

      @media screen and (min-width: 580px) {
        margin-right: 160px;
        width: 300px;
      }

      @media screen and (min-width: 800px) {
        width: 250px;
      }

      @media screen and (min-width: 900px) {
        width: 350px;
      }

      @media screen and (min-width: 1200px) {
        width: 550px;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      top: 7px;
    }

    .rst__tree {
      min-width: 700px;
    }
  }

  &-prevent-expand {
    margin: 0 2px 0 0 !important;
  }

  &-label {
    color: #5a595e;
    flex-grow: 1;
    font-weight: 400;
    margin: 0  0  0 15px;
    overflow: block;
    white-space: initial;

    .TaskItem-container--completed & {
      color: #d3d3d3;
    }

    &--first {
      width: 680px;

      @media screen and (min-width: 1300px) {
        width: 680px;
      }
    }

    &--second {
      width: 625px;
    }

    &--third {
      width: 595px;
    }

    &--fourth {
      width: 555px;
    }

    &--fifth {
      width: 120px;

      @media screen and (min-width: 380px) {
        width: 515px;
      }
    }
  }

  &-view-container {
    align-items: center;
    display: flex;
    flex-direction: row;
  }

  &-more-btn {
    background: none;
    border: unset;
    box-shadow: none;
    color: #bbbac8;
    font-size: 0.75rem;

    &:hover,
    &:focus,
    &:active {
      background: none;
    }

    &-container {
      opacity: 0;
      position: absolute !important;
      right: 0;
      transform: translateX(58px);

      .rst__node:hover & {
        opacity: 1;
      }
    }
  }

  &-edit {
    color: #bbbac8;
    cursor: pointer;
    font-size: 0.85rem;
    opacity: 0;
    position: absolute !important;
    right: 0;
    transform: translateX(30px);

    &:hover {
      color: var(--theme-primary);
    }

    .rst__node:hover & {
      opacity: 1;
    }
  }

  .rst__node &-dot {
    border-radius: 50%;
    display: inline-block;
    height: 10px !important;
    margin-top: 8px;
    width: 10px;
  }

  &-more-btn-container-active {
    display: block !important;
    opacity: 1;
  }

  /** show the more button on task container hover only */
  .TasksSection-container .rst__node:hover &-container {
    .TaskItem-more-btn-container {
      display: block;
    }

    .TaskItem-edit {
      display: block;
    }
  }

  &-checkbox {
    height: 18px;
    width: 18px;

    .ant-checkbox-inner {
      height: 18px;
      width: 18px;
    }
  }

  &-edit-container {
    border-radius: 5px;
    color: #bbbbc7;
    margin-bottom: 15px;
    margin-left: 15px;
    min-height: 50px;
    padding: 4px 0 5px;
    width: 300px;

    &--first {
      margin-right: 2px;
      width: 300px;

      @media screen and (min-width: 380px) {
        width: 350px;
      }

      @media screen and (min-width: 580px) {
        width: 500px;
      }

      @media screen and (min-width: 800px) {
        width: 450px;
      }

      @media screen and (min-width: 900px) {
        width: 550px;
      }

      @media screen and (min-width: 1200px) {
        width: 752px;
      }
    }

    &--second {
      margin-right: 68px;
      width: 280px;

      @media screen and (min-width: 380px) {
        width: 330px;
      }

      @media screen and (min-width: 580px) {
        margin-right: 38px;
        width: 450px;
      }

      @media screen and (min-width: 800px) {
        width: 400px;
      }

      @media screen and (min-width: 900px) {
        width: 500px;
      }

      @media screen and (min-width: 1200px) {
        width: 700px;
      }
    }

    &--third {
      margin-right: 138px;
      width: 260px;

      @media screen and (min-width: 380px) {
        width: 310px;
      }

      @media screen and (min-width: 580px) {
        margin-right: 76px;
        width: 400px;
      }

      @media screen and (min-width: 800px) {
        width: 350px;
      }

      @media screen and (min-width: 900px) {
        width: 450px;
      }

      @media screen and (min-width: 1200px) {
        width: 650px;
      }
    }

    &--fourth {
      margin-right: 205px;
      width: 240px;

      @media screen and (min-width: 380px) {
        width: 290px;
      }

      @media screen and (min-width: 580px) {
        margin-right: 115px;
        width: 350px;
      }

      @media screen and (min-width: 800px) {
        width: 300px;
      }

      @media screen and (min-width: 900px) {
        width: 400px;
      }

      @media screen and (min-width: 1200px) {
        width: 600px;
      }
    }

    &--fifth {
      margin-right: 275px;
      width: 220px;

      @media screen and (min-width: 380px) {
        width: 270px;
      }

      @media screen and (min-width: 580px) {
        margin-right: 160px;
        width: 300px;
      }

      @media screen and (min-width: 800px) {
        width: 250px;
      }

      @media screen and (min-width: 900px) {
        width: 350px;
      }

      @media screen and (min-width: 1200px) {
        width: 550px;
      }
    }

    .rst__tree {
      min-width: 700px;
    }

    .AddTask-container {
      background-color: white;
      z-index: 2001;
    }

    .AddTask-input-container {
      background-color: #fcfcfc;
    }
  }

  .rst__node &-collapse-container {
    align-items: center;
    background-color: #fcfcfc;
    border: 1px solid #ebebeb;
    border-radius: 50%;
    display: flex;
    font-size: 0.6rem;
    font-weight: 400;
    height: 25px !important;
    justify-content: center;
    position: absolute;
    transform: translate(-32px, 2px);
    width: 25px !important;

    i {
      font-size: 8px;
      margin: 2px 2px 0 0;
    }
  }
}
