.TodoActivityTypes {
  &-add-container,
  &-empty-container {
    align-items: center;
    color: #5a595e;
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  &-add-btn {
    background: #bbbbc7;
    border-radius: 5px;
    color: white;
    font-size: 0.6rem;
    text-transform: uppercase;

    &:hover,
    &:focus {
      background: #c8c8d4;
      border: unset;
      color: white;
    }
  }

  &-container {
    .rst__moveHandle {
      display: none;
    }
  }
}
