.TodoAssigneeDropdownPicker {
  &-container {
    background-color: white;
    border: 1px solid #e3e2eb;
    border-radius: 5px;
    min-width: 220px;
    padding: 10px 0;
  }

  &-body-container {
    max-height: 250px;
    overflow-y: auto;
  }

  &-search-container {
    margin: 0 10px 10px;

    .ant-input-affix-wrapper {
      border-radius: 5px;
      color: #97959c;
    }
  }

  &-no-labels {
    color: #5a595e;
    margin: 5px 0;
    text-align: center;
    width: 100%;
  }

  &-label-item {
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    font-size: 0.8rem;
    margin: 1px 10px;
    padding: 3px 5px;

    &-selected {
      background-color: #f9f9f9;
    }

    &-label {
      flex-grow: 1;
      max-width: 150px;
      pointer-events: none;
    }

    i {
      color: var(--theme-primary);
    }

    &:hover {
      background-color: #f9f9f9;
    }
  }

  &-avatar {
    margin: 0 8px 0 0;
    pointer-events: none;

    i {
      color: white;
    }
  }
}
