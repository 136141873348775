.Attachment {
  &-upload-container {
    display: flex;
    justify-content: center;
  }

  &-dropzone-container {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D8D8D8FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
    margin: 20px;
    min-height: 200px;
    outline: none;
    width: 260px;

    @media screen and (min-width: 400px) {
      width: 330px;
    }

    @media screen and (min-width: 600px) {
      width: 365px;
    }

    @media screen and (min-width: 800px) {
      width: 445px;
    }

    &:hover {
      background-color: white;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%231897F8FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='4' stroke-linecap='square'/%3e%3c/svg%3e");
    }
  }

  &-dropzone-inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
      border-radius: 5px;
      box-shadow: none;

      &:focus {
        background: var(--theme-dark);
        border-color: var(--theme-dark);
        color: white;
      }

      &:disabled {
        background: var(--theme-disabled) !important;
        border-color: var(--theme-disabled) !important;
        color: white !important;
      }

      &:hover {
        background: var(--theme-light);
        border-color: var(--theme-light);
        color: white;
      }
    }
  }

  &-upload {
    &-icon {
      color: #eeedf2;
      font-size: 3.6rem;
      margin: 5px 0 0;
    }

    &-text {
      color: var(--theme-secondary-main);
      margin: 0 0 20px;
    }
  }

  &-gallery {
    margin: 20px 0;

    &-title {
      font-size: 1rem;
      font-weight: 600;
      margin: 0 0 20px;
    }

    &-body {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
}
