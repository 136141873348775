.MeetingEventAgendaItem {
  &-container {
    align-items: center;
    display: flex;
    margin-bottom: 20px;

    input {
      border-radius: 5px;
      max-width: 450px;
      padding: 10px 15px;
    }
  }

  &-btn {
    color: var(--theme-secondary-main);
    margin-left: 10px;
  }
}
