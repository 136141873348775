.TaskReminderSelectItem {
  &-container {
    color: var(--theme-secondary-dark);
    cursor: pointer;
    font-size: 0.8rem;
    padding: 5px 15px;

    &:hover {
      background: var(--theme-secondary-light);
    }
  }
}
