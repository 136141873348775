.TodoProjectContent {
  &-add-task,
  &-add-section {
    margin-bottom: 25px;
    width: 100%;

    &-container {
      font-size: 1.05rem;

      h2 {
        color: var(--theme-primary);
      }
    }
  }

  &-main-section {
    margin: 10px 0;
  }

  &-sections {
    --sections-drag-handler: 1;

    .rst__moveHandle {
      opacity: var(--sections-drag-handler);
      transform: translateX(-20px);
    }
  }

  /** Adds a bottom padding and minimum height */
  &-container {
    min-height: 700px;
    padding-bottom: 40px;
    width: 300px;

    @media screen and (min-width: 380px) {
      width: 350px;
    }

    @media screen and (min-width: 580px) {
      width: 500px;
    }

    @media screen and (min-width: 800px) {
      width: 450px;
    }

    @media screen and (min-width: 900px) {
      width: 550px;
    }

    @media screen and (min-width: 1200px) {
      width: 750px;
    }

    /** restricts drag and drop on sorted container */
    &-sorted .ProjectTasksList-container .rst__moveHandle {
      visibility: hidden;
    }
  }

  &-title-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &-project-title {
    flex-grow: 1;
  }

  &-shared-avatars {
    cursor: pointer;
  }

  &-empty {
    align-content: center;
    animation: fadeAnimation 4s ease;
    display: flex;
    flex-direction: column;
  }

  &-not-found-img {
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }

  &-not-found-title {
    color: black;
    font-size: 1.2rem;
    margin-top: 30px;
    text-align: center;
  }

  &-not-found-text {
    color: var(--theme-secondary-main);
    margin-top: 10px;
    text-align: center;
  }

  &-sort-container {
    align-items: center;
    color: var(--theme-secondary-dark);
    display: flex;
    font-size: 0.8rem;
    margin: 0 10px;
    text-transform: uppercase;

    .ant-select-selector {
      color: var(--theme-secondary-dark);
      font-size: 0.7rem;
      padding: 0 8px !important;
    }

    .ant-select-arrow {
      top: 38%;
    }

    i {
      font-size: 0.9rem;
    }
  }

  &-more-container {
    color: var(--theme-secondary-main);
    cursor: pointer;
    font-size: 0.8rem;

    &:hover {
      color: var(--theme-primary);
    }
  }

  &-share-container {
    margin-right: 10px;
  }

  &-sort-order {
    display: none;
  }

  &-sort-order-enabled {
    cursor: pointer;
    display: block;

    &:hover {
      color: var(--theme-primary);
    }
  }

  &-sort-dropdown {
    width: 160px !important;

    .ant-select-item {
      color: var(--theme-secondary-dark);
      font-size: 0.9rem;
      text-transform: capitalize;
    }

    .ant-select-item-option-selected {
      background-color: inherit;
      color: black;
      font-weight: 600;
    }
  }
}

@keyframes fadeAnimation {
  from {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
