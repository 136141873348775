.FileInput {
  &-dropzone-container {
    border: 1px dashed #acacac;
    border-radius: 5px;
    height: 40px;
    width: 200px;

    @media screen and (min-width: 600px) {
      width: 340px;
    }

    @media screen and (min-width: 800px) {
      width: 540px;
    }
  }

  &-dropzone-inner {
    align-items: center;
    position: relative;

    p {
      opacity: 0;
      padding: 7px 0 0 12px;

      @media screen and (min-width: 600px) {
        opacity: 1;
      }
    }
  }

  &-computer-btn {
    background-color: inherit;
    border: unset !important;
    color: var(--theme-primary);
    cursor: pointer;
    outline: none;
    position: absolute;
    right: 5px;
    top: 7px;

    &:hover {
      text-decoration: underline;
    }
  }
}
