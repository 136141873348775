.TodoTaskLabelTag {
  &-label {
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 350;
    margin-bottom: 1px;
    margin-right: 10px;
    margin-top: 1px;
  }
}
