.TaskReminderViewItem {
  &-container {
    align-items: center;
    border: 1px solid var(--theme-secondary-light);
    border-radius: 5px;
    display: flex;
    font-size: 0.8rem;
    margin: 5px 15px;
  }

  &-history {
    background: var(--theme-secondary-light);
    border-right: 1px solid var(--theme-secondary-light);
    color: var(--theme-secondary-main);
    padding: 5px 10px;
  }

  &-date {
    color: var(--theme-secondary-dark);
    flex-grow: 1;
    padding: 5px;
  }

  &-delete {
    background: inherit;
    border: unset !important;
    box-shadow: unset !important;
    color: var(--theme-secondary-main);

    &:hover,
    &:focus {
      color: var(--theme-delete-main) !important;
    }
  }

  &-passed &-history {
    border: unset;
  }

  &-passed &-delete {
    visibility: hidden;
  }

  &-passed &-date {
    color: var(--theme-secondary-main);
    text-decoration: line-through;
  }

  &-passed {
    background: var(--theme-secondary-light);
  }
}
