.SettingsHeader {
  &-container {
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  &-content {
    display: flex;
    max-width: 1200px;
    padding: 0 30px;
    width: 100%;

    @media screen and (min-width: 1120px) {
      padding: 0;
      width: 80%;
    }
  }

  &-logo-container {
    align-items: center;
    color: (--theme-secondary-dark);
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 1120px) {
      min-width: 300px;
      padding: 0 20px;
    }

    i {
      font-size: 1.03rem;
      font-weight: 600 !important;
      margin-top: 4px;
    }
  }

  &-logo {
    align-items: center;
    color: black;
    display: flex;
    padding-top: 5px;

    img {
      margin-bottom: 5px;
    }
  }

  &-main-content {
    align-items: center;
    color: var(--theme-secondary-main);
    display: flex;
    width: 100%;
  }

  &-content-title {
    flex-grow: 1;
    font-size: 0.8rem;
    font-weight: 200;
    visibility: hidden;

    @media screen and (min-width: 500px) {
      visibility: visible;
    }
  }

  &-cancel-btn {
    align-items: center;
    background: white;
    border: unset;
    border-radius: 5px;
    box-shadow: none;
    color: var(--theme-secondary-dark);
    display: flex;
    font-size: 0.7rem;
    justify-content: flex-end;
    margin-right: 5px;
    padding: 0 5px !important;
    text-transform: uppercase;

    i {
      color: var(--theme-secondary-main);
      font-size: 16px;
      margin-left: 10px;
    }

    &:hover,
    &:focus {
      background-color: var(--theme-secondary-light);
      color: var(--theme-secondary-dark);
      font-weight: 500;

      i {
        color: var(--theme-delete-main);
      }
    }
  }

  &-apps {
    border-radius: 17px;
    cursor: pointer;
    display: flex;
    height: 35px;
    justify-content: center;
    padding: 6px 5px 5px 7px;
    width: 35px;

    &:hover {
      background-color: var(--theme-secondary-light);
    }
  }

  &-extended-txt {
    display: none;

    @media screen and (min-width: 500px) {
      display: block;
    }
  }
}
