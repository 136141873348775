.UploadToolbar {
  &-container {
    width: 100%;

    .ant-upload-list-item-info {
      min-width: 100%;
    }
  }

  &-btn {
    border: unset !important;
    color: gray !important;
    font-size: 1rem;
    padding: 0 5px 5px;
  }
}
