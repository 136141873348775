.TodoShareUserItem {
  &-user {
    &-item {
      align-items: flex-start;
      display: flex;
      padding: 10px;
      width: 100%;
    }

    &-description {
      flex-grow: 1;
      margin: 0 15px;
    }

    &-pending {
      background: var(--theme-delete-main);
      border-radius: 5px;
      color: white;
      font-size: 0.6rem;
      font-weight: 300;
      margin-right: 15px;
      margin-top: 4px;
      padding: 3px 5px;
      text-transform: uppercase;
    }

    &-name {
      color: black;
      font-size: 0.9rem;
      font-weight: 500;
    }

    &-email {
      color: var(--theme-secondary-main);
      font-size: 0.85rem;
      font-weight: 300;
    }

    &-delete {
      color: var(--theme-secondary-main);
      cursor: pointer;
      font-size: 1.1rem;

      &:hover {
        color: var(--theme-delete-main);
      }
    }
  }
}
