.ShareProject {
  &-link-container {
    background-color: var(--theme-secondary-light);
    border-radius: 15px;
    display: flex;
    overflow: visible;
    padding: 20px 15px;
    position: absolute;
    transform: translate(-16px, 60px);
    width: 100%;

    button {
      background-color: black !important;
      border-radius: 5px;
      color: white !important;
      font-size: 0.8rem;

      &:hover {
        background-color: #202020 !important;
      }
    }

    input {
      border-radius: 5px;
      color: var(--theme-secondary-main);
      flex-grow: 1;
      text-overflow: ellipsis;

      &:focus {
        color: black;
      }
    }
  }

  &-container {
    margin: 10px 0 5px;

    .ant-alert {
      position: absolute;
      width: 92%;
      z-index: 100;
    }

    .ant-input-affix-wrapper {
      border-radius: 5px;
    }

    .ant-input:focus.focus-visible {
      outline: unset !important;
    }

    .ant-input-suffix {
      button {
        all: unset;
        color: #bbb;
        cursor: pointer;
        padding: 0 5px;
      }
    }
  }

  &-empty-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px 0;
    width: 100%;
  }

  &-users-body {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    max-height: 216px;
    overflow-y: auto;
    width: 100%;

    .ant-divider-horizontal {
      margin: 4px 0;
    }
  }

  &-subtitle {
    color: black;
    font-size: 1.1rem;
    font-weight: 500;
  }

  &-paragraph {
    color: rgb(146, 146, 146);
  }

  &-switch-container {
    display: flex;
    left: 40px;
    margin-top: 2px;
    position: absolute;
  }

  &-switch-title {
    color: var(--theme-secondary-main);
    font-weight: 350;
    margin-left: 10px;
  }

  &-link-input {
    flex-grow: 1;
    margin-right: 10px;
  }

  &-user {
    &-item {
      align-items: flex-start;
      display: flex;
      padding: 10px;
      width: 100%;
    }

    &-description {
      flex-grow: 1;
      margin: 0 15px;
    }

    &-owner {
      color: var(--theme-secondary-main);
      font-weight: 300;
      margin-right: 15px;
      margin-top: 2px;
    }

    &-name {
      color: black;
      font-size: 0.9rem;
      font-weight: 500;
    }

    &-email {
      color: var(--theme-secondary-main);
      font-size: 0.85rem;
      font-weight: 300;
    }

    &-delete {
      color: var(--theme-secondary-main);
      cursor: pointer;
      font-size: 1.1rem;

      &:hover {
        color: var(--theme-delete-main);
      }
    }
  }

  &-dot {
    border-radius: 50%;
    height: 7px;
    margin: 1px 8px 0 10px;
    width: 7px;
  }

  &-modal-title {
    align-items: center;
    display: flex;
  }

  &-project-title {
    color: var(--theme-secondary-dark);
    font-size: 0.9rem;
    margin-right: 10px;
  }

  &-modal {
    min-height: 700px;

    .ant-modal-content {
      background-color: inherit;
      box-shadow: none;
      min-height: 550px;
    }

    .ant-modal-footer {
      background-color: white;
      border-radius: 0 0 15px 15px;
      min-height: 70px;
    }
  }

  &-leave-modal-title {
    color: #fe4b87;
  }

  &-modal-container {
    .anticon {
      color: #fe4b87 !important;
    }

    .ant-btn {
      border-radius: 5px;
    }

    .ant-btn-primary {
      background: #fe4b87;
      border-color: #fe4b87;
      color: white;

      &:hover {
        background: #f50756;
        border-color: #f50756;
        color: white;
      }
    }
  }
}
