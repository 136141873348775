.withFolderLayout {
  &-container {
    background: #e7eaef;
    margin: 20px 0;

    .UpcomingBadge-container {
      position: absolute;
      transform: translate(40px, -20px);
    }
  }

  &-header {
    border: 1px solid #e3e5eb;
    color: #bbbbc7;
    cursor: pointer;

    &-inner {
      align-items: center;
      display: flex;
      flex-direction: row;
      padding: 7px 14px 10px 42px;
      width: 285px;
    }

    &:hover {
      background: #fcfcfc;
    }
  }

  &-label {
    color: #252525;
    flex-grow: 1;
    font-weight: 450;
    margin: 3px 12px 0 8px;
    transition: all 0.7s ease;

    &-active {
      color: black;
      font-weight: 550;
      margin: 3px 12px 0 8px;
    }
  }

  &-body {
    height: auto;
    max-width: 285px;
    overflow: hidden;
    padding: 2px 0 10px;

    &-animation-off {
      animation: none !important;
    }
  }

  &-body-hidden {
    animation: collapse 0.7s ease;
    max-height: 0;
    overflow: hidden;
    padding: 0;
  }

  &-body-active {
    animation: expand 0.7s ease;
  }

  &-body-inactive {
    animation: none !important;
  }

  &-add-icon .ant-btn-circle {
    background: inherit;
    border: unset !important;
    box-shadow: none !important;
    color: inherit;

    i {
      font-size: 12px;
      font-weight: 600;
    }

    &:hover {
      color: var(--theme-primary);
    }
  }

  &-expand-icon {
    margin-top: 3px;
    transition: color 0.7s ease-out;
  }

  &-expand-icon-active {
    color: black;
    margin-top: 3px;
  }

  &-add-icon {
    transition: transform 0.7s ease-out;

    &-active {
      transform: rotate(180deg);
    }
  }
}

@keyframes collapse {
  from {
    max-height: 600px;
  }

  to {
    max-height: 0;
  }
}

@keyframes expand {
  from {
    max-height: 0;
  }

  to {
    max-height: 600px;
  }
}
