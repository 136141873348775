.TimePicker-date {
  border: 1px solid var(--theme-secondary-light);
  border-radius: 3px;
  color: var(--theme-secondary-dark);
  text-align: center;

  &:focus {
    border-color: var(--theme-primary);
    box-shadow: 0 0 0 1px #bad7fb;
    color: black;
  }

  &:hover {
    border-color: var(--theme-hover);
  }
}

.react-datepicker--time-only {
  border: 1px solid var(--theme-secondary-light);
  min-width: 168px;

  .react-datepicker__time-container {
    width: 100%;
  }

  .react-datepicker__header--time--only {
    display: none;
  }

  .react-datepicker__time {
    width: 100%;
  }

  .react-datepicker__time-box {
    width: 100% !important;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__time-list-item {
    color: var(--theme-secondary-dark);
  }

  .react-datepicker__time-list-item--selected {
    background: unset !important;
    color: var(--theme-light) !important;
  }
}

.react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 0 !important;
}
