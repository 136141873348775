.RecurrenceDayPicker {
  &-row {
    align-items: center;
    display: flex;
    padding: 10px 20px;
  }

  &-container {
    display: flex;
    flex-wrap: wrap;
    width: 340px;

    .MomentTimePicker-container {
      margin: 0 5px 5px 0;
    }
  }

  &-label {
    color: var(--theme-secondary-dark);
    font-weight: 500;
    min-width: 90px;
  }

  &-plus {
    border: unset !important;
    color: var(--theme-secondary-main);
    outline: none !important;

    &:focus {
      outline: none !important;
    }
  }
}
