.AddTaskComment {
  &-container {
    align-items: center;
    color: #c0c0cc;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }

  &-options-container {
    display: flex;
    font-size: 1.05rem;
    justify-content: space-evenly;
    width: 60px;

    @media screen and (min-width: 400px) {
      width: 80px;
    }

    @media screen and (min-width: 600px) {
      width: 100px;
    }

    @media screen and (min-width: 800px) {
      width: 120px;
    }
  }

  &-body-container {
    width: 155px;

    @media screen and (min-width: 400px) {
      width: 200px;
    }

    @media screen and (min-width: 600px) {
      width: 340px;
    }

    @media screen and (min-width: 800px) {
      width: 540px;
    }

    input {
      border: unset !important;
      box-shadow: unset !important;
      padding: 7px;
      width: 100%;
    }
  }

  &-send-container {
    font-size: 1.1rem;
  }

  &-options-item {
    cursor: pointer;

    &:hover,
    &-active {
      color: var(--theme-primary);
    }
  }

  &-attachments-container {
    border-radius: 5px;
    padding-top: 10px;
    position: absolute;
    transform: translateY(-115%);
    width: 270px;

    @media screen and (min-width: 400px) {
      width: 290px;
    }

    @media screen and (min-width: 600px) {
      width: 340px;
    }

    @media screen and (min-width: 800px) {
      width: 540px;
    }
  }
}
