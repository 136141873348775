.TodoHeader {
  &-header-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    .ant-badge-count {
      transform: translate(-10px, 4px);
    }

    .ant-badge-multiple-words {
      transform: translate(1px, 3px);
    }
  }

  &-logo-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    min-width: 0;
    overflow: hidden;
    padding: 0;
    width: 0;

    @media screen and (min-width: 800px) {
      min-width: 300px;
      padding: 0 25px;
    }

    i {
      color: var(--theme-secondary-dark);
      font-size: 0.9rem;
      margin-top: 6px;
    }

    img {
      margin-bottom: 5px;
    }
  }

  &-logo-container-open {
    background-color: white;
    height: 60px;
    left: 0;
    padding: 0 25px;
    padding-right: calc(100% - 300px + 25px);
    position: absolute;
    width: 100%;
    z-index: 100;

    @media screen and (min-width: 400px) {
      padding: 0 25px;
      width: 300px;
    }
  }

  &-menu-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-end;
    min-width: 300px;
    padding: 10px 30px 10px 0;
  }

  &-search-input {
    background-color: var(--theme-secondary-light);
    border: black;
    border-radius: 40px;
    color: var(--theme-secondary-dark);
    margin: 10px 12px 10px 0;
    max-width: 55px;
    min-height: 30px;
    overflow: hidden;
    transition: all 0.7s ease-in-out;

    @media screen and (min-width: 500px) {
      max-width: 200px;
    }

    .ant-input-prefix {
      margin: 0 0 0 8px;
    }

    .ant-input.focus-visible {
      outline: unset !important;
    }

    input {
      background-color: var(--theme-secondary-light);
      color: var(--theme-secondary-dark);
      padding: 4px 20px !important;
      transition: all 0.7s ease-in-out;

      &::placeholder {
        color: var(--theme-secondary-dark);
      }
    }

    &:hover {
      max-width: 95% !important;
      z-index: 1;

      @media screen and (min-width: 800px) {
        max-width: 580px !important;
      }

      @media screen and (min-width: 1600px) {
        max-width: 100% !important;
      }
    }

    &.ant-input-affix-wrapper-focused {
      background-color: white;
      color: black;

      input {
        background-color: white;
        color: black;
      }

      @media screen and (min-width: 1600px) {
        max-width: 100% !important;
      }
    }

    &-container {
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;
      right: 0;
      width: 100%;

      @media screen and (min-width: 800px) {
        width: calc(100% - 300px);
      }
    }

    &-inner-container {
      width: 300px;

      @media screen and (min-width: 380px) {
        width: 350px;
      }

      @media screen and (min-width: 580px) {
        width: 500px;
      }

      @media screen and (min-width: 800px) {
        width: 450px;
      }

      @media screen and (min-width: 900px) {
        width: 550px;
      }

      @media screen and (min-width: 1200px) {
        width: 750px;
      }
    }
  }

  @mixin menu-btn-mixin {
    background-color: inherit;
    border: unset;
    box-shadow: none;
    color: var(--theme-secondary-dark);
  }

  &-menu-item {
    @include menu-btn-mixin;

    margin: 0 8px;

    &:hover {
      background-color: var(--theme-secondary-light);
      color: var(--theme-secondary-dark);
    }
  }

  &-menu-item-corner {
    @include menu-btn-mixin;

    box-shadow: 0 0 0 0.7px rgba(240, 240, 240, 0.842);
    font-size: 0.95rem;
    margin-left: 8px;
    margin-right: 0;

    &:hover {
      background-color: var(--theme-secondary-light);
      color: var(--theme-secondary-dark);
    }
  }

  &-add-task-modal {
    .ant-modal-title {
      color: var(--theme-primary);
    }

    .ant-modal-body {
      background-color: white;
    }

    .ant-modal-header {
      border-bottom: unset;
      padding-bottom: 0;
    }
  }

  &-apps-container {
    border-radius: 17px;
    cursor: pointer;
    display: flex;
    height: 35px;
    justify-content: center;
    padding: 5px;
    width: 35px;

    &:hover {
      background-color: var(--theme-secondary-light);
    }
  }

  &-app-logo {
    align-items: center;
    display: flex;
    margin-top: 5px;
  }

  &-app-font {
    font-size: 1.05rem;
    margin: 0 0 4px 5px;
  }

  &-add-task-btn {
    background: var(--theme-primary);
    border-radius: 5px;
    color: white;
    font-size: 0.68rem;
    text-transform: uppercase;

    @media screen and (max-width: 550px) {
      padding: 0 8px 0 12px;
    }

    &:hover {
      background: var(--theme-light);
      color: white;
    }

    &:focus {
      background: var(--theme-dark);
      color: white;
    }

    i {
      margin-right: 5px;
    }
  }

  &-menu-bars {
    display: block;
    margin: 0 10px 0 25px;

    @media screen and (min-width: 800px) {
      display: none;
    }
  }

  &-sider-menu-bars {
    display: block;

    @media screen and (min-width: 800px) {
      display: none;
    }
  }

  &-extended-btn-txt {
    display: none !important;

    @media screen and (min-width: 600px) {
      display: inline-block !important;
    }
  }
}
