.Todo {
  &-add-task {
    margin: 15px 0;

    &-container {
      font-size: 1.05rem;
      min-width: 700px;
      padding: 0 18px 15px 0;

      h2 {
        color: var(--theme-primary);
      }

      @media screen and (max-width: 1200px) {
        padding-left: auto;
        padding-right: 0;
      }

      @media screen and (min-width: 1201px) {
        padding-left: 30px;
      }
    }
  }
}
