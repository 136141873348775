.ColorPicker {
  &-container {
    align-items: center;
    display: flex;
    justify-content: space-around;

    .ant-checkbox-inner {
      background-color: var(--cp-background) !important;
      border-color: var(--cp-background) !important;
      border-radius: 50%;
      padding: 12px;
    }

    .ant-checkbox {
      &:hover,
      &:focus,
      &::after {
        border-color: var(--cp-background) !important;
        border-radius: 50% !important;
      }
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
      margin: 2px;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      left: 8px;
    }
  }

  &-more-btn {
    background: inherit;
    border: unset;
    box-shadow: none;
    color: gray;
  }

  &-chrome-picker {
    cursor: pointer;
  }
}
