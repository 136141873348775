.TodoLabelOptionsPanel {
  &-container {
    background-color: white;
    border: 0.5px solid #f0f0f0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    padding: 5px 0;

    .ant-divider {
      margin: 4px 0;
    }
  }

  &-item {
    align-items: center;
    border: unset;
    border-radius: 5px;
    box-shadow: none;
    box-sizing: border-box;
    color: #828282 !important;
    display: flex;
    font-size: 0.7rem;
    font-weight: 400;
    justify-content: flex-start !important;
    margin: 2px 5px;
    transition: color 0.3s ease-in;

    i {
      color: #babac6;
      font-size: 0.8rem;
      margin: 0 10px 0 0;
      transition: color 0.3s ease-in;
      width: 20px;
    }

    &:hover {
      background-color: #f9f9f9;
    }

    &:last-child {
      &:hover {
        color: #cc4643 !important;

        i {
          color: #cc4643;
        }
      }
    }
  }
}
