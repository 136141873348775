.TodoCommentDelete {
  &-container {
    color: gray;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 10px;
    transform: translate(38px);

    &:hover {
      color: var(--theme-primary);
    }
  }
}
