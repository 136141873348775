.TaskReminderCustomSelect {
  &-container {
    padding: 0 15px;
  }

  &-number {
    margin-right: 10px;
    width: 50px;

    .ant-input-number-handler-wrap {
      border: unset;
      opacity: 1;
    }

    .ant-input-number-handler-down {
      border: unset;
    }

    .ant-input-number-handler-up {
      border: unset;
    }
  }

  &-interval {
    margin-right: 5px;
    width: 90px;
  }

  &-confirm {
    border: unset !important;
    box-shadow: unset !important;
    color: var(--theme-secondary-main);
    margin-right: 2px;
  }

  &-cancel {
    border: unset !important;
    box-shadow: unset !important;
    color: var(--theme-secondary-main);
    outline: none !important;

    &:hover {
      color: var(--theme-delete-main);
    }
  }

  &-dropdown {
    min-width: 80px;

    .ant-select-item-option {
      border-radius: 0;
      color: var(--theme-secondary-dark);
      font-size: 0.8rem;
      font-weight: 400;
      margin: 0;

      &:hover {
        background: var(--theme-secondary-light);
      }
    }

    .ant-select-item-option-selected {
      background: var(--theme-secondary-light);
      color: var(--theme-primary);
    }
  }
}
