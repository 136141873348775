.DetailedTasksList {
  &-container {
    .rst__moveHandle,
    .rst__loadingHandle {
      left: 0;
      margin-top: 0;
      position: absolute;
      top: 50%;
      transform: translate(-15px, -50%) !important;
    }

    .rst__collapseButton,
    .rst__expandButton {
      margin-top: 8px;
    }

    .TaskViewItem-project {
      display: none;
    }

    .TaskItem-container--first {
      width: 330px;

      @media screen and (min-width: 400px) {
        width: 350px;
      }

      @media screen and (min-width: 600px) {
        width: 495px;
      }

      @media screen and (min-width: 800px) {
        width: 690px;
      }
    }

    .TaskItem-edit-container .AddTask-container {
      background-color: inherit;
    }

    .TaskItem-edit-container--first {
      min-width: unset;
      padding-left: 0;
      width: 330px;

      @media screen and (min-width: 400px) {
        width: 350px;
      }

      @media screen and (min-width: 600px) {
        width: 495px;
      }

      @media screen and (min-width: 800px) {
        width: 695px;
      }
    }

    &-empty {
      border-radius: 5px;
      color: #7e7e7e;
      min-height: 50px;
      padding: 5px 0 15px;
    }
  }

  &-completed-title {
    color: black;
    font-size: 0.8rem;
    font-weight: 500;
    margin: 30px 0 10px;
  }

  &-completed-section {
    .rst__moveHandle {
      display: none;
    }

    .rst__collapseButton,
    .rst__expandButton {
      transform: translateX(28px);
    }
  }

  &-completed-container {
    margin: 15px 0 10px 15px;
  }
}
